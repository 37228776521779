import { mergeStyleSets, Stack } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import GroupClient, {
    IGroupMembership,
    IGroup,
    IGroupPolicyViolation,
    IGroupPolicyViolationCANDARuleDetailsSignedVersion,
    ICANDAMetadata,
} from 'clients/group-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { AssignmentValueTableCell } from 'components/core/attributes/assignment-value-table-cell';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import { AuthContext } from 'contexts/auth-context';
import { AttributeDataType } from 'personnel-core-clients';
import React, { ReactNode, useContext, useEffect } from 'react';
import { doNothingAsync } from 'utils/misc-utils';

interface ViewMemberChangeModalActionButtonProps {
    memberDetails: IGroupMembership;
    group: IGroup;
    principalId: string;
    ruleId: string;
    policies: IGroupPolicyViolation[];
    onDeleteMemberFromUARTable: (personnelId: string, ruleId?: string) => void;
}

function ViewMemberChangeModalActionButton(props: ViewMemberChangeModalActionButtonProps) {
    const authContext = useContext(AuthContext);
    const [isApplyEnabled, setApplyEnabled] = React.useState(true);
    const [agreement, setAgreement] = React.useState<ICANDAMetadata>();

    useEffect(() => {
        const fetchAgreement = async () => {
            try {
                const agreement = await GroupClient.getAgreement(
                    authContext,
                    props.group.id,
                    props.ruleId,
                    props.principalId,
                );
                setApplyEnabled(agreement != null);
                setAgreement(agreement);
            } catch (e) {
                console.error('Error getting agreement', e);
                throw 'Error getting agreement';
            }
        };

        fetchAgreement();
    }, [authContext, props.group.id, props.ruleId, props.principalId]);

    const onHandleAssignmentChange = async (): Promise<void> => {
        if (!props.ruleId) {
            throw 'No UAR found';
        }

        try {
            await GroupClient.signAgreement(
                authContext,
                props.group!.id,
                props.ruleId,
                props.principalId,
            );
            props.onDeleteMemberFromUARTable(props.memberDetails.personnelId, props.ruleId);
        } catch (e) {
            console.error('Error signing member agreement', e);
            throw 'Error approving member.';
        }
    };

    const oneRow = (
        title: string | JSX.Element,
        content: ReactNode,
        options?: {
            verticalAlign?: 'start';
        },
    ): JSX.Element => {
        return (
            <Stack
                tokens={{ padding: 7 }}
                horizontal
                wrap
                verticalAlign={options?.verticalAlign ?? 'center'}
                className={styles.tableStack}>
                <Stack.Item className={styles.itemNameColumn} tabIndex={0}>
                    {title}
                </Stack.Item>
                <Stack.Item className={styles.itemValueColumn} tabIndex={0}>
                    {content}
                </Stack.Item>
            </Stack>
        );
    };

    return (
        <ModalActionButton<void>
            enableSubmit={true}
            shouldHideCancelButton={false}
            enableCancel={isApplyEnabled}
            cancelButtonText='Approve'
            text={'View Change'}
            iconName={IconNames.EntryView}
            modalTitle={'Member Change'}
            submitButtonText={'Close'}
            onSubmit={doNothingAsync}
            onCancel={onHandleAssignmentChange}
            onButtonClick={doNothingAsync}
            onModalConcluded={doNothingAsync}>
            {oneRow(
                'Employee',
                <CoreEmployeeHoverCardFromPrincipalId
                    principalId={props.memberDetails.personnelId}
                />,
            )}
            {oneRow(
                'Attribute',
                agreement?.assignmentChangedDetails?.attributeName != null
                    ? agreement?.assignmentChangedDetails?.attributeName
                    : 'No data',
            )}
            {oneRow(
                'Previous Value',
                agreement?.assignmentChangedDetails?.previousAssignmentValue != null ? (
                    <AssignmentValueTableCell
                        value={agreement?.assignmentChangedDetails?.previousAssignmentValue}
                        attributeType={agreement?.assignmentChangedDetails?.attributeDataType}
                    />
                ) : (
                    'No data'
                ),
            )}
            {oneRow(
                'New Value',
                agreement?.assignmentChangedDetails?.currentAssignmentValue != null ? (
                    <AssignmentValueTableCell
                        value={agreement?.assignmentChangedDetails?.currentAssignmentValue}
                        attributeType={agreement?.assignmentChangedDetails?.attributeDataType}
                    />
                ) : (
                    'No data'
                ),
            )}
        </ModalActionButton>
    );
}

export default ViewMemberChangeModalActionButton;

const styles = mergeStyleSets({
    tableStack: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        lineHeight: 35,
        marginBottom: 20,
    },
    itemNameColumn: {
        width: 200,
        flexShrink: 0,
        alignItems: 'flex-start',
    },
    itemValueColumn: {
        width: 400,
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: 0,
    },
});
