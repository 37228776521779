import { MessageBar, MessageBarType } from '@fluentui/react';
import { Dictionary } from 'assets/constants/global-constants';
import GroupClient, {
    GroupRole,
    GroupRuleType,
    ICANDAMetadata,
    IGroupMembership,
} from 'clients/group-client';
import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import Spacer from 'components/common/spacer';
import { IManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { IAuthContext } from 'contexts/auth-context';
import pluralize from 'pluralize';
import React from 'react';
import { toTitleCase } from 'utils/string-utils';

const manageGroupBreadcrumbRoot: ICustomBreadcrumbItem[] = [
    {
        title: 'Groups',
        link: '/groups/manage',
    },
];

export function groupAgreementsBreadcrumbs(
    agreementMetadata: ICANDAMetadata | undefined,
): ICustomBreadcrumbItem[] {
    if (!agreementMetadata?.groupName) {
        return manageGroupBreadcrumbRoot;
    }
    return [
        ...manageGroupBreadcrumbRoot,
        {
            title: agreementMetadata.groupName,
            link: '',
        },
        {
            title:
                agreementMetadata.ruleType === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE
                    ? 'User access review'
                    : 'Agreements and Attestations',
            link: '',
        },
    ];
}

export function manageGroupBreadcrumbs(
    groupName: string | undefined,
    tab: string | undefined,
): ICustomBreadcrumbItem[] {
    if (!groupName || !tab) {
        return manageGroupBreadcrumbRoot;
    }

    return [
        ...manageGroupBreadcrumbRoot,
        {
            title: groupName,
            link: '',
        },
        {
            title: toTitleCase(tab),
            link: '',
        },
    ];
}

export const fetchLinkedSecurityGroups = async (
    authContext: IAuthContext,
    groupContext: IManageGroupContext,
    groupId: string,
): Promise<void> => {
    try {
        groupContext.setErrorGettingLinkedSecurityGroups(false);
        groupContext.setLinkedSecurityGroups([]);
        const linkedSecurityGroupsResult = await GroupClient.getLinkedSecurityGroups(
            authContext,
            groupId,
        );
        groupContext.setLinkedSecurityGroups(linkedSecurityGroupsResult);
    } catch (error) {
        groupContext.setErrorGettingLinkedSecurityGroups(true);
        console.log('Error loading linked security groups');
    }
};

export const MinimumRequiredOwners = 2;

export const fteOwnerCount = (
    groupOwners: IGroupMembership[],
    ownerIsFTE: Dictionary<boolean>,
): number => {
    return groupOwners.filter(
        (o) =>
            o.role === GroupRole.OWNER &&
            // If employee record of owner is unavailable, be pessimistic
            // and assume the person is not an FTE.
            !!ownerIsFTE[o.personnelId],
    ).length;
};

export const hasEnoughFTEOwners = (
    groupOwners: IGroupMembership[],
    ownerIsFTE: Dictionary<boolean>,
): boolean => {
    // A group needs to have at least two owners. Don't allow
    // role demote unless group has more than 2 owners.
    return fteOwnerCount(groupOwners, ownerIsFTE) > MinimumRequiredOwners;
};

export const displayWarningIfFewOwners = (
    groupOwners: IGroupMembership[],
    ownerIsFTE: Dictionary<boolean>,
    isGroupOwnersObtained: boolean,
    isMin2FTEOwnersFeatureFlagEnabled: boolean,
): JSX.Element => {
    if (
        !isMin2FTEOwnersFeatureFlagEnabled ||
        !isGroupOwnersObtained ||
        hasEnoughFTEOwners(groupOwners, ownerIsFTE)
    ) {
        return <></>;
    }
    const fteOwnerCountVar = fteOwnerCount(groupOwners, ownerIsFTE);
    return (
        <>
            <MessageBar messageBarType={MessageBarType.warning}>
                The group only has {fteOwnerCountVar} FTE {pluralize('owner', fteOwnerCountVar)}.
                Deleting and recategorizing them has been disabled until more are added.
            </MessageBar>
            <Spacer marginTop={10} />
        </>
    );
};
