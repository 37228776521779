import {
    ChoiceGroup,
    Dropdown,
    IChoiceGroupOption,
    IPersonaProps,
    MessageBar,
    MessageBarType,
    Stack,
    TextField,
    mergeStyleSets,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { IconNames } from 'assets/constants/global-constants';
import { CorePrincipalsClient } from 'clients/core/personnel-core-client-wrappers';
/* eslint-disable no-restricted-imports -- Single component for both internal and external rules */
import EligibilityClient, { IAttribute, IEligibility } from 'clients/eligibility-client';
/* eslint-disable no-restricted-imports -- Single component for both internal and external rules */
import GroupClient, {
    EnabledUserAccessReviewApproverTypes,
    GroupRuleType,
    ICourseDetail,
    IGroup,
    IGroupRule,
    lifecycleEventOptionsKeys,
    UserAccessReviewApproverType,
} from 'clients/group-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import Modal, { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import { Table } from 'components/common/table';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import {
    MemberManagerEnum,
    costCenterOptions,
    costCenterOptionsEvaluationType,
    costCenterOptionsKeys,
} from 'components/groups/manage-group/policy/buttons/create-policy-rule/create-edit-policy-rule-modal-action-button';
import policyRulesTableColumns from 'components/groups/manage-group/policy/policy-rules-table-columns';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { PrincipalGetByIdsRequest } from 'personnel-core-clients';
import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useFetchSimple, useToggle } from 'utils/misc-hooks';
import { doNothing, doNothingAsync } from 'utils/misc-utils';
import { transformPrincipalRecordToPersona } from 'utils/persona-utils';
import { dateToLocalLongDateFormat } from 'utils/time-utils';

interface IPolicyRulesModalActionButtonProps {
    group: IGroup;
    policyRules: IGroupRule[];
    updateRulesList: ([]: IGroupRule[]) => void;
    refetchRules: () => void;
    updateListAfterEdit?: (rule: IGroupRule) => void;
    updatePolicyRulesTableAfterEdit?: () => void;
}

export default function PolicyRulesModalActionButton(
    props: IPolicyRulesModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const [allowList, setAllowList] = useState<IPersonaProps[]>([]);
    const [isViewRuleDialogShown, toggleIsViewRuleDialogShown] = useToggle(false);
    const [currentRule, setCurrentRule] = useState<IGroupRule | undefined>(undefined);
    const [fetchedBasicRules, setFetchedBasicRules] = useState<IGroupRule[]>();
    const [attributes, setAttributes] = useState<IAttribute[] | undefined>(undefined);
    const [eligibilities, setEligibilities] = useState<IEligibility[] | undefined>(undefined);
    const [trainingCheckCourses, setTrainingCheckCourses] = useState<ICourseDetail[]>();
    const managerCheckoptions: IChoiceGroupOption[] = [
        { key: MemberManagerEnum.Member, text: MemberManagerEnum.Member },
        { key: MemberManagerEnum.Manager, text: MemberManagerEnum.Manager },
    ];
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const lifecycleOptions = [{ key: 0, text: 'Duration' }];

    const deleteRuleFromList = (ruleId: string): void => {
        const tempList = props.policyRules.filter((rule) => rule.id !== ruleId);
        props.updateRulesList(tempList);
    };

    const updateListAfterEdit = props.updateListAfterEdit;
    const updatePolicyRulesTableAfterEdit = props.updatePolicyRulesTableAfterEdit;

    const isAdmin: boolean = authContext.isInRole(Role.GroupAdmin);
    const isManager: boolean = groupContext.isManager();
    const isAuditor: boolean = groupContext.isAuditor();
    const handleOpenViewRuleModal = (rule: IGroupRule): void => {
        toggleIsViewRuleDialogShown();
        setCurrentRule(rule);
    };

    const handleDate = (date: number | undefined | null): JSX.Element => {
        return date !== null && date ? (
            <div>{dateToLocalLongDateFormat(date * 1000)}</div>
        ) : (
            <div>N/A</div>
        );
    };

    useFetchSimple<IGroupRule[]>({
        dependencies: [],
        canPerformFetch: true,
        fetchFunc: async () => GroupClient.getGroupRulesBasic(authContext),
        onSuccess: (result) => {
            if (result) {
                setFetchedBasicRules(result);
            }
        },
        onError: () => {
            throw 'Unable to retrieve group rule information.';
        },
        onFinally: doNothing,
    });

    const fetchAttributes = async (attributeIds: string[] | undefined): Promise<void> => {
        try {
            if (!attributeIds || attributeIds.length === 0) {
                return;
            }

            const attributes = await EligibilityClient.getAttributes(authContext);
            if (attributes && attributes.length > 0) {
                const foundAttributes = attributes.filter((attr) => attributeIds.includes(attr.id));

                setAttributes(foundAttributes);
            }
        } catch {
            setErrorMessage('Unable to fetch policy rule attributes');
        }
    };

    const fetchEligibilities = async (eligibilityIds: string[] | undefined): Promise<void> => {
        try {
            if (!eligibilityIds || eligibilityIds.length === 0) {
                return;
            }

            const eligibilities = await EligibilityClient.getEligibilities(authContext);
            if (eligibilities && eligibilities.length > 0) {
                const foundEligibilities = eligibilities.filter((elig) =>
                    eligibilityIds.includes(elig.id),
                );

                setEligibilities(foundEligibilities);
            }
        } catch {
            setErrorMessage('Unable to fetch policy rule eligibilities');
        }
    };

    const onHandleAllowList = async (allowList: string[] | undefined): Promise<void> => {
        if (groupContext.group && allowList && allowList.length > 0) {
            try {
                const principalClient = new CorePrincipalsClient(authContext);
                const result = await principalClient.getByIds({
                    principalIds: allowList,
                } as PrincipalGetByIdsRequest);
                const initialBlockListVar = result.map((person) =>
                    transformPrincipalRecordToPersona(person),
                );

                setAllowList(initialBlockListVar);
            } catch (e) {
                console.error(e);

                setErrorMessage('Unable to edit policy rule');
            }
        }
    };

    const handleViewRuleModalConcluded = async (): Promise<void> => {
        toggleIsViewRuleDialogShown();
        setCurrentRule(undefined);
        setAllowList([]);
        setAttributes(undefined);
        setEligibilities(undefined);
        setErrorMessage(undefined);
    };

    const renderManagerCheckChoiceGroup = (): boolean => {
        switch (currentRule?.type) {
            case GroupRuleType.US_CITIZENSHIP_CHECK_RULE:
            case GroupRuleType.CLOUD_SCREENING_CHECK_RULE:
            case GroupRuleType.FTE_CHECK_RULE:
                return true;
            default:
                return false;
        }
    };

    const renderMarkdownPreview = (): boolean => {
        switch (currentRule?.type) {
            case GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE:
                if (
                    currentRule.subtype !== null &&
                    currentRule.subtype !== lifecycleEventOptionsKeys.AssignmentChangeLifecycle
                ) {
                    return true;
                } else {
                    return false;
                }
            case GroupRuleType.CA_NDA_CHECK_RULE:
                return true;
            default:
                return false;
        }
    };

    const handleGroupIds = async (groupIds: string[] | undefined): Promise<void> => {
        try {
            if (!groupIds || groupIds.length === 0) {
                return;
            }

            const courseDetails = await GroupClient.getCourseDetails(authContext, groupIds);
            setTrainingCheckCourses(courseDetails);
        } catch {}
    };

    useEffect(() => {
        if (currentRule) {
            onHandleAllowList(currentRule.allowList);
            handleGroupIds(currentRule.courseIds);
            fetchAttributes(currentRule.attributeIds);
            fetchEligibilities(currentRule.eligibilityIds);
            setErrorMessage(undefined);
        }
    }, [currentRule]);

    const label = (labelName: string): JSX.Element => (
        <div className={styles.label}>{labelName}</div>
    );
    const onButtonclick = (): void => {
        props.refetchRules();
    };
    const basicRules = fetchedBasicRules;

    return (
        <ModalActionButton
            text='Policy Rules'
            size={ModalSizeType.large}
            enable={true}
            iconName={IconNames.EntitlementPolicy}
            modalTitle='Policy Rules'
            enableSubmit={true}
            shouldHideCancelButton={true}
            submitButtonText='Close'
            onButtonClick={onButtonclick}
            onSubmit={doNothingAsync}
            onModalConcluded={(): void => setCurrentRule(undefined)}>
            <Modal
                title='View Rule'
                isOpen={isViewRuleDialogShown}
                size={ModalSizeType.large}
                shouldHideCancelButton={true}
                submitButtonText='Close'
                onSubmit={handleViewRuleModalConcluded}
                onCancel={doNothing}>
                {label('Name')}
                <div>{currentRule?.name}</div>
                <Spacer marginTop={10} />
                {label('Description')}
                <div>{currentRule?.description}</div>
                <Spacer marginTop={10} />

                {label('Type')}
                <div>{currentRule?.type}</div>
                <Spacer marginTop={10} />

                {errorMessage && (
                    <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
                )}

                {renderManagerCheckChoiceGroup() && (
                    <>
                        <ChoiceGroup
                            defaultSelectedKey={
                                currentRule?.managerCheck
                                    ? MemberManagerEnum.Manager
                                    : MemberManagerEnum.Member
                            }
                            disabled={true}
                            options={managerCheckoptions}
                            className={styles.choiceGroup}
                        />
                        <Spacer marginTop={20} />
                    </>
                )}

                {renderMarkdownPreview() && currentRule?.CANDAText && (
                    <>
                        {currentRule.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE && (
                            <>
                                <Dropdown
                                    label='Lifecycle Event'
                                    selectedKey={0}
                                    options={[{ key: 0, text: currentRule.subtype!.toString() }]}
                                    disabled={true}
                                />
                                <Spacer marginTop={10} />
                                {currentRule.subtype ===
                                    lifecycleEventOptionsKeys.DurationLifecycle && (
                                    <TextField
                                        value={currentRule.durationDays.toString()}
                                        label='Duration Period'
                                        disabled={true}
                                    />
                                )}
                                {currentRule.subtype ===
                                    lifecycleEventOptionsKeys.AssignmentChangeLifecycle && (
                                    <TextField
                                        value={currentRule.attributeId}
                                        label='Attribute'
                                        disabled={true}
                                    />
                                )}
                                <ChoiceGroup
                                    options={EnabledUserAccessReviewApproverTypes.map((option) => {
                                        return { key: option, text: option };
                                    })}
                                    label='Approver'
                                    className={styles.choiceGroup}
                                    selectedKey={
                                        currentRule.approverType ??
                                        UserAccessReviewApproverType.Member
                                    }
                                    disabled={true}
                                />
                                <Spacer marginTop={15} />
                            </>
                        )}
                        <Spacer marginTop={5} />
                        <div>
                            {label(
                                currentRule.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE
                                    ? 'Contract Text'
                                    : `Agreement & Attestation Rule Text`,
                            )}
                        </div>
                        <div className={styles.markdownPreview}>
                            <ReactMarkdown
                                // eslint-disable-next-line react/no-children-prop
                                children={currentRule?.CANDAText.text}
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                            />
                        </div>
                        <Spacer marginTop={10} />
                    </>
                )}

                {currentRule?.attributeIds && attributes !== undefined && (
                    <>
                        <div>
                            Member must have <b>any</b> of the following attributes:
                        </div>
                        <Spacer marginTop={5} />
                        {attributes.map((item, i) => (
                            <div key={`${item.id}_${i}`} className={styles.messageBarContainer}>
                                <MessageBar className={styles.messageBar}>
                                    {item.attributeDesc}
                                </MessageBar>
                            </div>
                        ))}
                    </>
                )}

                {currentRule?.eligibilityIds && eligibilities !== undefined && (
                    <>
                        <div>
                            Member must have <b>any</b> of the following eligibilities:
                        </div>
                        <Spacer marginTop={5} />
                        {eligibilities.map((item, i) => (
                            <div key={`${item.id}_${i}`} className={styles.messageBarContainer}>
                                <MessageBar className={styles.messageBar}>
                                    {item.eligibilityName}
                                </MessageBar>
                            </div>
                        ))}
                    </>
                )}

                {currentRule?.courseIds && trainingCheckCourses && (
                    <>
                        <div>
                            Member must have <b>any</b> of the following courses:
                        </div>
                        <Spacer marginTop={5} />
                        {trainingCheckCourses.map((item, i) => (
                            <div key={`${item.id}_${i}`} className={styles.messageBarContainer}>
                                <MessageBar className={styles.messageBar}>{item.name}</MessageBar>
                            </div>
                        ))}
                    </>
                )}

                {currentRule?.countryCodes && (
                    <>
                        <div>
                            Member must be a compliant member in <b>any</b> of the following Country
                            Codes:
                        </div>
                        <Spacer marginTop={5} />
                        {currentRule.countryCodes.map((item, i) => (
                            <div key={`${item}_${i}`} className={styles.messageBarContainer}>
                                <MessageBar className={styles.messageBar}>{item}</MessageBar>
                            </div>
                        ))}
                    </>
                )}

                {currentRule?.companyCodes && (
                    <>
                        <div>
                            Member must be a compliant member in <b>any</b> of the following Company
                            Codes:
                        </div>
                        <Spacer marginTop={5} />
                        {currentRule.companyCodes.map((item, i) => (
                            <div key={`${item}_${i}`} className={styles.messageBarContainer}>
                                <MessageBar className={styles.messageBar}>{item}</MessageBar>
                            </div>
                        ))}
                    </>
                )}

                {currentRule?.costCenterCodes && (
                    <>
                        <div>Members must</div>
                        <ChoiceGroup
                            disabled={true}
                            options={costCenterOptions}
                            className={styles.choiceGroup}
                            defaultSelectedKey={
                                currentRule.evaluationType ===
                                costCenterOptionsEvaluationType.Inclusive
                                    ? costCenterOptionsKeys.True
                                    : costCenterOptionsKeys.False
                            }
                        />
                        <Spacer marginTop={10} />
                        <div>
                            Member must be in <b>any</b> of the following Cost Center Codes:
                        </div>
                        <Spacer marginTop={5} />
                        {currentRule.costCenterCodes.map((item, i) => (
                            <div key={`${item}_${i}`} className={styles.messageBarContainer}>
                                <MessageBar className={styles.messageBar}>{item}</MessageBar>
                            </div>
                        ))}
                    </>
                )}

                {allowList.length > 0 && (
                    <>
                        <div>Allow List</div>
                        <Spacer marginTop={5} />
                        <Stack horizontal wrap>
                            {allowList
                                .filter((item) => !!item.itemProp)
                                .map((item) => {
                                    const itemProp = JSON.parse((item.itemProp as string) ?? '{}');
                                    if (!itemProp.id) {
                                        return;
                                    }
                                    const principalId = itemProp.id;
                                    if (!!principalId) {
                                        return (
                                            <Stack.Item key={principalId}>
                                                <span>&nbsp;</span>
                                                <CoreEmployeeHoverCardFromPrincipalId
                                                    key={principalId}
                                                    principalId={principalId}
                                                />
                                            </Stack.Item>
                                        );
                                    }
                                })}
                        </Stack>
                        <Spacer marginTop={10} />
                    </>
                )}
                {label('Effective Start Date')}
                {handleDate(currentRule?.effectiveStartTimestampUTC)}
                <Spacer marginTop={10} />

                {label('Effective End Date')}
                {handleDate(currentRule?.effectiveEndTimestampUTC)}
                <Spacer marginTop={10} />

                {label('Grace Period (In Days)')}
                <div>{currentRule?.gracePeriod}</div>
                <Spacer marginTop={10} />
                {currentRule?.suspended && (
                    <div style={{ color: 'red' }}>This rule is currently suspended.</div>
                )}
            </Modal>
            {basicRules && (
                <Table
                    tableColumns={policyRulesTableColumns({
                        deleteRuleFromList,
                        handleOpenViewRuleModal,
                        isAdmin,
                        updateListAfterEdit,
                        updatePolicyRulesTableAfterEdit,
                        isManager,
                        isAuditor,
                        basicRules,
                    })}
                    rows={props.policyRules ?? []}
                    isFetchingData={false}
                    tableName='Policy Rules'
                    noDataText={
                        props.policyRules.length > 0 ? '' : 'This group has no policy rules.'
                    }
                />
            )}
        </ModalActionButton>
    );
}

const styles = mergeStyleSets({
    choiceGroup: {
        selectors: {
            '.ms-ChoiceFieldGroup-flexContainer': {
                display: 'flex',
            },
            '.ms-ChoiceField': {
                marginRight: 15,
            },
        },
    },
    label: {
        fontWeight: 600,
    },
    messageBarContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        selectors: {
            '& div:nth-child(1)': {
                width: 'auto',
            },
            '& div:nth-child(2)': {
                paddingLeft: 10,
                paddingRight: 10,
            },
        },
    },
    messageBar: {
        selectors: {
            '.ms-MessageBar-icon': {
                display: 'none',
            },
        },
    },
    markdownPreview: {
        border: `1px solid ${NeutralColors.gray130}`,
        minHeight: '60px',
        height: 250,
        marginTop: 5,
        overflow: 'scroll',
        padding: '6px 8px',
        selectors: {
            'p': {
                margin: '0 0 10px 0',
            },
        },
    },
});
