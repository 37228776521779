/* eslint-disable @typescript-eslint/naming-convention */
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    Dropdown,
    IDropdownOption,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    TextField,
} from '@fluentui/react';
import GroupClient, { ICANDAMetadata } from 'clients/group-client';
import { AuthContext } from 'contexts/auth-context';
import DOMPurify from 'dompurify';
import React, { useContext, useState } from 'react';

export enum ApproveOrDenyUarModalMode {
    Approve,
    Deny,
}

const enum JustificationDropdownOptionKeys {
    NoLongerRequired,
    DoesNotMeetRequirements,
    Other,
}

export interface IApproveOrDenyUarModalProps {
    onDismiss: () => void;
    onSuccess: () => void;
    approveOrDenyMode: ApproveOrDenyUarModalMode;
    principalName: string;
    agreementMetadata: ICANDAMetadata;
    onError: (approveOrDeny: string) => void;
    principalId: string;
}

export function ApproveOrDenyUarModal(props: IApproveOrDenyUarModalProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [message, setMessage] = useState<string>(
        `<div>Are you sure you want to deny this UAR for <strong>${props.principalName}?</strong> <br /> This may result in the member's removal from the group.</div>`,
    );
    const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.success);
    const [showJustificationInput, setShowJustificationInput] = useState<boolean>(false);
    const [justificationMessage, setJustificationMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const justificationDropdownOptions: IDropdownOption[] = [
        {
            key: JustificationDropdownOptionKeys.NoLongerRequired,
            text: 'No longer required for role',
        },
        {
            key: JustificationDropdownOptionKeys.DoesNotMeetRequirements,
            text: 'Member does not meet requirements',
        },
        { key: JustificationDropdownOptionKeys.Other, text: 'Other' },
    ];

    const onClose = (): void => {
        setIsLoading(false);
        props.onDismiss();
        setJustificationMessage('');
    };

    const onSuccess = (): void => {
        setIsLoading(false);
        props.onDismiss();
        setJustificationMessage('');
        props.onSuccess();
    };

    const onSubmit = async (): Promise<void> => {
        if (props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny) {
            try {
                setIsLoading(true);
                await GroupClient.denyAgreement(
                    authContext,
                    props.agreementMetadata.groupId,
                    props.agreementMetadata.ruleId,
                    justificationMessage,
                    props.principalId,
                );
                onSuccess();
            } catch {
                onClose();
                props.onError('deny');
            }
        }
    };

    const onJustificationDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void => {
        if (option?.key === JustificationDropdownOptionKeys.Other) {
            setJustificationMessage('');
            setShowJustificationInput(true);
        } else {
            setShowJustificationInput(false);
            setJustificationMessage(option?.text ?? '');
        }
    };

    return (
        <Dialog
            hidden={false}
            onDismiss={onClose}
            dialogContentProps={{
                onDismiss: onClose,
                title:
                    props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny
                        ? 'Deny UAR confirmation'
                        : 'Approve UAR confirmation',
                showCloseButton: true,
            }}
            modalProps={{
                styles: {
                    main: {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '600px !important',
                        maxWidth: '600px !important',
                        borderRadius: '8px',
                        background: 'var(--Light-Background-Background-1, #FFF)',
                        boxShadow:
                            '0px 32px 64px 0px rgba(0, 0, 0, 0.24), 0px 0px 8px 0px rgba(0, 0, 0, 0.20)',
                    },
                },
                className: `${style.dialogTitleStyle}`,
            }}>
            <MessageBar
                styles={{
                    root: {
                        borderRadius: '4px',
                        border: '1px solid var(--Status-Success-Stroke-1-Rest, #9FD89F)',
                        background: 'var(--Status-Success-Background-1-Rest, #F1FAF1)',
                    },
                    iconContainer: {
                        alignItems: 'center',
                    },
                }}
                messageBarType={messageType}>
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
            </MessageBar>
            <Dropdown
                className={style.dropdownStyle}
                styles={{
                    dropdownOptionText: {
                        color: 'rgba(66, 66, 66, 1)',
                    },
                    dropdownItem: {
                        paddingLeft: '10px',
                    },
                    title: {
                        borderRadius: '4px',
                        border: '1px solid rgba(209, 209, 209, 1)',
                    },
                    dropdown: {
                        marginTop: '8px',
                    },
                }}
                placeholder='Select justification'
                options={justificationDropdownOptions}
                onChange={onJustificationDropdownChange}
            />
            {showJustificationInput && (
                <TextField
                    styles={{
                        root: { marginTop: '8px' },
                        fieldGroup: {
                            border: '1px solid rgba(209, 209, 209, 1)',
                            borderRadius: '4px',
                        },
                    }}
                    type='text'
                    onChange={(ev, val): void => {
                        setJustificationMessage(val!);
                    }}
                    placeholder='Add justification'
                />
            )}
            <DialogFooter
                styles={{
                    actions: {
                        marginTop: '8px',
                    },
                }}>
                <PrimaryButton
                    disabled={justificationMessage === '' || isLoading}
                    text={
                        props.approveOrDenyMode === ApproveOrDenyUarModalMode.Approve
                            ? 'Approve'
                            : 'Deny'
                    }
                    styles={{
                        root: {
                            height: '32px',
                            width: '57px',
                            minWidth: '0px',
                            borderRadius: '4px',
                        },
                    }}
                    onClick={onSubmit}
                />
                <DefaultButton
                    text='Cancel'
                    styles={{
                        root: {
                            height: '32px',
                            width: '66px',
                            minWidth: '0px',
                            borderRadius: '4px',
                            border: '1px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                            background: '1px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                        },
                    }}
                    onClick={onClose}
                />
            </DialogFooter>
        </Dialog>
    );
}

const style = mergeStyleSets({
    dropdownStyle: {
        '.ms-Callout-main': {
            borderRadius: '4px !important',
        },
    },
    dialogTitleStyle: {
        '.ms-Dialog-title': {
            padding: '24px 46px 8px 24px',
        },
    },
});
