import { ActionButton, IColumn, mergeStyleSets } from '@fluentui/react';
import { IconNames, xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import { IGroup } from 'clients/group-client';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import NavLinkContainer from 'components/common/navlink-container';
import { TableCell } from 'components/common/table';
import { percentCompliant } from 'components/groups/groups-utils';
import React from 'react';
import { ISortableColumns } from 'utils/sort-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';

export enum GroupsTableColumnNames {
    group = 'Group',
    memberCount = 'Member Count',
    percentCompliant = '% Compliant',
    dateCreated = 'Date Created',
    lastModifiedDate = 'Last Modified',
    supportsLegacyRules = 'Supports Legacy Rules',
    actions = 'Actions',
}

export function tableColumnsGroups(params: ISortableColumns, isInternal: boolean): IColumn[] {
    const columnWidths = {
        group: 100,
        memberCount: 100,
        percentCompliant: 100,
        supportsLegacyRules: 100,
        dateCreated: 100,
        lastModifiedDate: 100,
        actions: 75,
    };

    const columns: IColumn[] = [
        {
            key: GroupsTableColumnNames.group,
            name: GroupsTableColumnNames.group,
            ariaLabel: GroupsTableColumnNames.group,
            minWidth: columnWidths.group,
            maxWidth: columnWidths.group * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === GroupsTableColumnNames.group,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(GroupsTableColumnNames.group);
            },
            onRender: (row: IGroup): JSX.Element => (
                <TableCell key={`${row.id}-${GroupsTableColumnNames.group}`}>
                    <EllipsisTextCss text={row.name} />
                </TableCell>
            ),
        },
        {
            key: GroupsTableColumnNames.memberCount,
            name: GroupsTableColumnNames.memberCount,
            ariaLabel: GroupsTableColumnNames.memberCount,
            minWidth: columnWidths.memberCount,
            maxWidth: columnWidths.memberCount * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === GroupsTableColumnNames.memberCount,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(GroupsTableColumnNames.memberCount);
            },
            onRender: (row: IGroup): JSX.Element => (
                <TableCell style={{ width: 'auto' }} key={`${row.id}-memberCo`}>
                    <div style={{ justifyContent: 'flex-start' }}>
                        <PlaceText>{row.metrics?.memberCount ?? 0}</PlaceText>
                    </div>
                </TableCell>
            ),
        },
        {
            key: GroupsTableColumnNames.percentCompliant,
            name: GroupsTableColumnNames.percentCompliant,
            ariaLabel: GroupsTableColumnNames.percentCompliant,
            minWidth: columnWidths.percentCompliant,
            maxWidth: columnWidths.percentCompliant * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === GroupsTableColumnNames.percentCompliant,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(GroupsTableColumnNames.percentCompliant);
            },
            onRender: (row: IGroup): JSX.Element => {
                return (
                    <TableCell style={{ width: 'auto' }} key={`${row.id}-name`}>
                        <PlaceText>{`${percentCompliant(row).toFixed(0)} %`}</PlaceText>
                    </TableCell>
                );
            },
        },
        {
            key: GroupsTableColumnNames.dateCreated,
            name: GroupsTableColumnNames.dateCreated,
            ariaLabel: GroupsTableColumnNames.dateCreated,
            minWidth: columnWidths.dateCreated,
            maxWidth: columnWidths.dateCreated * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === GroupsTableColumnNames.dateCreated,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(GroupsTableColumnNames.dateCreated);
            },
            onRender: (row: IGroup): JSX.Element => (
                <TableCell style={{ width: 'auto' }} key={`${row.id}-name`}>
                    <span>
                        {timeToString(row.creationTimestampUTC * 1000, TimeFormats.MMMDDYYYY)}
                    </span>
                </TableCell>
            ),
        },
        {
            key: GroupsTableColumnNames.lastModifiedDate,
            name: GroupsTableColumnNames.lastModifiedDate,
            ariaLabel: GroupsTableColumnNames.lastModifiedDate,
            minWidth: columnWidths.lastModifiedDate,
            maxWidth: undefined,
            isSorted: params.sortColumn === GroupsTableColumnNames.lastModifiedDate,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(GroupsTableColumnNames.lastModifiedDate);
            },
            onRender: (row: IGroup): JSX.Element => (
                <TableCell style={{ width: 'auto' }} key={`${row.id}-name`}>
                    <span>
                        {timeToString(row.lastModifiedTimestampUTC * 1000, TimeFormats.MMMDDYYYY)}
                    </span>
                </TableCell>
            ),
        },
        {
            key: GroupsTableColumnNames.actions,
            name: GroupsTableColumnNames.actions,
            ariaLabel: GroupsTableColumnNames.actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions,
            ...lastColumnStylesButton,
            onRender: (row: IGroup): JSX.Element => {
                return (
                    <TableCell style={{ width: 'auto' }} key={`${row.id}-actions`}>
                        <NavLinkContainer to={`/groups/manage/${row.id}`}>
                            <ActionButton text={'View'} iconProps={{ iconName: IconNames.View }} />
                        </NavLinkContainer>
                    </TableCell>
                );
            },
        },
    ];

    if (isInternal) {
        columns.splice(3, 0, {
            key: GroupsTableColumnNames.supportsLegacyRules,
            name: GroupsTableColumnNames.supportsLegacyRules,
            ariaLabel: GroupsTableColumnNames.supportsLegacyRules,
            minWidth: columnWidths.supportsLegacyRules,
            maxWidth: columnWidths.supportsLegacyRules * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === GroupsTableColumnNames.supportsLegacyRules,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(GroupsTableColumnNames.supportsLegacyRules);
            },
            onRender: (row: IGroup): JSX.Element => (
                <TableCell
                    style={{ width: 'auto' }}
                    key={`${row.id}-${GroupsTableColumnNames.supportsLegacyRules}`}>
                    <EllipsisTextCss text={`${row.supportsLegacyRuleTypes}`} />
                </TableCell>
            ),
        });
    }

    return columns;
}

interface IPlaceTextProps {
    children: string | number;
}

function PlaceText(props: IPlaceTextProps): JSX.Element {
    return (
        <div className={styles.root}>
            <span className={styles.text}>{props.children}</span>
        </div>
    );
}
const styles = mergeStyleSets({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    text: {
        width: 60,
        textAlign: 'right',
    },
});
