import { Stack } from '@fluentui/react';
import GroupClient, { IGroup } from 'clients/group-client';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import DownloadCsvReportButton from 'components/common/buttons/download-csv-report-button';
import CheckRole from 'components/common/check-role';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { Table } from 'components/common/table';
import { percentCompliant } from 'components/groups/groups-utils';
import { ManageGroupsFilterContext } from 'components/groups/manage-groups/contexts/manage-groups-filter-context';
import { ManageGroupsVariableContext } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import CreateGroupModalActionButton from 'components/groups/manage-groups/create-group-modal-action-button';
import ManageGroupsFilters from 'components/groups/manage-groups/manage-groups-filters';
import {
    GroupsTableColumnNames,
    tableColumnsGroups,
} from 'components/groups/manage-groups/table-columns-groups';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import React, { useContext, useEffect, useMemo } from 'react';
import { strCmp, useSortColumnHandler } from 'utils/sort-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

export default function ManageGroups(): JSX.Element {
    const filterContext = useContext(ManageGroupsFilterContext);
    const variableContext = useContext(ManageGroupsVariableContext);
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const isNonExternalEnabled = useFeatureFlag(FeatureFlagKeys.enableNonExternalComponents)
        .enabled;

    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler('Group');

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([
            { title: 'Groups', link: '' },
            { title: 'Manage', link: '' },
        ]);
    }, []);

    const sortGroups = (groups: IGroup[]): IGroup[] => {
        type A = IGroup;

        const chooseSortCmp = (): ((r1: A, r2: A) => number) => {
            switch (sortColumn) {
                case GroupsTableColumnNames.group:
                    return (r1: A, r2: A): number => sortAscending * strCmp(r1.name, r2.name);
                case GroupsTableColumnNames.memberCount:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        ((r1.metrics?.memberCount ?? 0) - (r2.metrics?.memberCount ?? 0));
                case GroupsTableColumnNames.dateCreated:
                    return (r1: A, r2: A): number =>
                        sortAscending * (r1.creationTimestampUTC - r2.creationTimestampUTC);
                case GroupsTableColumnNames.lastModifiedDate:
                    return (r1: A, r2: A): number =>
                        sortAscending * (r1.lastModifiedTimestampUTC - r2.lastModifiedTimestampUTC);
                case GroupsTableColumnNames.percentCompliant:
                    return (r1: A, r2: A): number =>
                        sortAscending * (percentCompliant(r1) - percentCompliant(r2));
                case GroupsTableColumnNames.supportsLegacyRules:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        (r1.supportsLegacyRuleTypes === r2.supportsLegacyRuleTypes
                            ? 0
                            : r1.supportsLegacyRuleTypes
                            ? -1
                            : 1);
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp();
        return [...groups].sort(sortCmp);
    };

    const filterGroups = (groups: IGroup[]): IGroup[] => {
        const mdy = (date: number): string => {
            return timeToString(date, TimeFormats.MDYYYY);
        };
        return groups.filter((group) => {
            if (
                !!filterContext.name &&
                !group.name.toLocaleLowerCase().includes(filterContext.name.toLocaleLowerCase())
            ) {
                return false;
            }
            if (
                !!filterContext.dateCreated &&
                mdy(group.creationTimestampUTC * 1000) !== mdy(filterContext.dateCreated.getTime())
            ) {
                return false;
            }
            if (
                !!filterContext.lastModifiedDate &&
                mdy(group.lastModifiedTimestampUTC * 1000) !==
                    mdy(filterContext.lastModifiedDate.getTime())
            ) {
                return false;
            }
            if (
                filterContext.lowerPercentageCompliant !== undefined &&
                percentCompliant(group) < filterContext.lowerPercentageCompliant
            ) {
                return false;
            }
            if (
                filterContext.upperPercentageCompliant !== undefined &&
                percentCompliant(group) > filterContext.upperPercentageCompliant
            ) {
                return false;
            }
            if (
                filterContext.lowerMemberCount !== undefined &&
                (group.metrics?.memberCount ?? 0) < filterContext.lowerMemberCount
            ) {
                return false;
            }
            if (
                filterContext.upperMemberCount !== undefined &&
                (group.metrics?.memberCount ?? 0) > filterContext.upperMemberCount
            ) {
                return false;
            }
            return true;
        });
    };

    const sortedFilteredGroups = useMemo(() => {
        return sortGroups(filterGroups(variableContext.groups));
    }, [
        variableContext.groups,
        sortAscending,
        sortColumn,
        filterContext.name,
        filterContext.dateCreated,
        filterContext.lastModifiedDate,
        filterContext.lowerPercentageCompliant,
        filterContext.upperPercentageCompliant,
        filterContext.lowerMemberCount,
        filterContext.upperMemberCount,
    ]);

    const tableColumns = useMemo(() => {
        return tableColumnsGroups(
            {
                sortColumn,
                sortAscending: sortAscending === 1,
                sortColumnHandler,
            },
            isNonExternalEnabled,
        );
    }, [sortColumn, sortAscending, filterContext.name, isNonExternalEnabled]);

    const showGroupCount = (): JSX.Element => {
        return (
            <>
                {sortedFilteredGroups.length}
                <span> of </span>
                {variableContext.groups.length}
            </>
        );
    };

    return (
        <CheckRole requiredRolesAny={[Role.GroupAdmin]} redirectNotInRole={'/groups'}>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <div>
                <SidebarAndContents>
                    <SidebarPane>
                        <ManageGroupsFilters />
                    </SidebarPane>
                    <ContentPane>
                        <ProblemLoadingMsg problemLoadingMsg={variableContext.errorFetchingGroups}>
                            <HorizontalBar horizontalAlign='space-between' verticalAlign='center'>
                                <Stack.Item>
                                    <Stack horizontal verticalAlign='center'>
                                        <Stack.Item>
                                            <h1 className={horizontalBarTitleStyle}>Admin</h1>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <span>&nbsp;</span>
                                            {showGroupCount()}
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Stack horizontal>
                                        <CreateGroupModalActionButton />
                                        <DownloadCsvReportButton
                                            getData={(): Promise<string> => {
                                                return GroupClient.downloadGroupsReport(
                                                    authContext,
                                                );
                                            }}
                                            fileNamePrefix={'groups'}
                                        />
                                    </Stack>
                                </Stack.Item>
                            </HorizontalBar>
                            <Table
                                rows={sortedFilteredGroups}
                                tableColumns={tableColumns}
                                isFetchingData={variableContext.isInitialGroupsLoad}
                                shimmerLabel='Loading groups...'
                                noDataText={
                                    variableContext.isInitialGroupsLoad ||
                                    variableContext.groups.length > 0
                                        ? ''
                                        : 'There are no groups'
                                }
                                tableName='Groups'
                            />
                        </ProblemLoadingMsg>
                    </ContentPane>
                </SidebarAndContents>
            </div>
        </CheckRole>
    );
}
