import {
    ActionButton,
    Checkbox,
    ChoiceGroup,
    DatePicker,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Dropdown,
    IChoiceGroupOption,
    IDropdownOption,
    IPersonaProps,
    Label,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField,
    TooltipHost,
    addDays,
    mergeStyleSets,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import {
    CoreAttributesClient,
    CorePrincipalsClient,
    obtainPrincipalRecords,
} from 'clients/core/personnel-core-client-wrappers';
/* eslint-disable no-restricted-imports -- Single component for both internal and external rules */
import EligibilityClient from 'clients/eligibility-client';
import { EmployeeMetadataType } from 'clients/employee-client';
/* eslint-enable no-restricted-imports -- Single component for both internal and external rules */
import GroupClient, {
    EnabledUserAccessReviewApproverTypes,
    GroupRuleRequestRuleData,
    GroupRuleType,
    ICourseDetail,
    IGroupRule,
    IPrincipalAttributeExpression,
    IStandDownCheckRule,
    UserAccessReviewApproverType,
    lifecycleEventOptionsKeys,
} from 'clients/group-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { CoreMultiPrincipalPersonaPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import { EmployeeListPickerUnlimited } from 'components/common/employee-list-picker';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import AttributePolicyBuilder from 'components/groups/manage-group/policy/builder/attribute-policy-builder';
import CountryOrCompanyCodePickerDisplay from 'components/groups/manage-group/policy/buttons/create-policy-rule/country-or-company-code-picker-display';
import CoursePickerDisplay from 'components/groups/manage-group/policy/buttons/create-policy-rule/course-picker-display';
import GroupMembershipRulePicker from 'components/groups/manage-group/policy/buttons/create-policy-rule/group-membership-rule-picker';
import OptionSelectDisplay from 'components/groups/manage-group/policy/buttons/create-policy-rule/option-select-display';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import {
    AttributeStatus,
    AttributeVisibilityLevel,
    GetAttributeResult,
} from 'personnel-core-clients';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useToggle } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import {
    getPersonasFromPrincipalRecords,
    getPrincipalRecordsFromPersonas,
} from 'utils/persona-utils';
import { separateWordsByCapitalLetter } from 'utils/string-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

export interface ICreatePolicyRuleModalActionButton {
    isEditing: boolean;
    ruleData?: IGroupRule;
    rules?: IGroupRule[];
    updateListAfterEdit?: (rule: IGroupRule) => void;
    updatePolicyRulesTableAfterEdit?: () => void;
}

export enum AttributeOrEligibility {
    attribute = 'Attribute',
    eligibility = 'Eligibility',
}

export enum MemberManagerEnum {
    Member = 'Member',
    Manager = 'Manager',
}

export enum costCenterOptionsKeys {
    True = 'true',
    False = 'false',
}

export enum lifecycleEventOptionsText {
    Duration = 'Duration',
    AssignmentChange = 'Assignment Change',
}

export enum costCenterOptionsEvaluationType {
    Inclusive = 'Inclusive',
    Exclusive = 'Exclusive',
}

export const costCenterOptions: IChoiceGroupOption[] = [
    { key: costCenterOptionsKeys.True, text: 'belong to the cost center' },
    { key: costCenterOptionsKeys.False, text: 'not belong to the cost center' },
];

const sideBySideComponentWidth = '47%';

export const lifecycleEventOptions: IDropdownOption[] = [
    { key: lifecycleEventOptionsKeys.DurationLifecycle, text: lifecycleEventOptionsText.Duration },
    {
        key: lifecycleEventOptionsKeys.AssignmentChangeLifecycle,
        text: lifecycleEventOptionsText.AssignmentChange,
    },
];

const dynamicGroupAvailableRules = new Set([
    GroupRuleType.PRINCIPAL_ATTRIBUTE_CHECK_RULE,
    GroupRuleType.CA_NDA_CHECK_RULE,
    GroupRuleType.STAND_DOWN_CHECK_RULE,
    GroupRuleType.TRAINING_CHECK_RULE,
    GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE,
]);

// Common components for Policy Rule types
// 1. Type dropdown - sets global state for modal
// 2. Name - textfield with Validation (required)
// 3. Description - textarea with default text for each Type - editable***
// 4. Attribute / Eligibility picker - dropdown that selects
// 5. Member / Manager radio buttons
// 6. Allow List multi personnel picker
// 7. Markdown text with Preview
// 8. Start/end date && Grace period
// 9. Suspend policy enforcement / Notifications
// 10. Employee multi personnel picker - for Org Check rule

export default function CreateEditPolicyRuleModalActionButton(
    props: ICreatePolicyRuleModalActionButton,
): JSX.Element {
    const groupContext = useContext(ManageGroupContext);
    const authContext = useContext(AuthContext);
    const [, toggleFetch] = useToggle(false);
    const [attributeOrEligibilityIDs, setAttributeOrEligibilityIDs] = useState<string[]>([]);
    const [groupMembershipCheckIDS, setGroupMembershipCheckIDS] = useState<string[]>([]);
    const [HRDataCheckCourses, setHRDataCheckCourses] = useState<string[]>([]);
    const [trainingCourses, setTrainingCourses] = useState<ICourseDetail[]>([]);
    const [trainingCoursesFromEdit, setTrainingCoursesFromEdit] = useState<ICourseDetail[]>([]);
    const [HRMetaDataType, setHRMetaDataType] = useState<EmployeeMetadataType | undefined>();
    const [description, setDescription] = useState<string>();
    const [policyRuleName, setPolicyRuleName] = useState<string>();
    const [effectiveStartDate, setEffectiveStartDate] = useState<Date | undefined>();
    const [effectiveEndDate, setEffectiveEndDate] = useState<Date | undefined>();
    const [isInvalidatePriorAgreementChecked, setIsInvalidatePriorAgreementChecked] = useState<
        boolean
    >(false);
    const [gracePeriod, setGracePeriod] = useState<number | undefined>(0);
    const [isSuspendPolicyEnforcement, setIsSuspendPolicyEnforcement] = useState<boolean>(false);
    const [isSuspendPolicyNotifications, setIsSuspendPolicyNotifications] = useState<boolean>(
        false,
    );
    const [isSendNewPolicyNotifications, setIsSendNewPolicyNotifications] = useState<boolean>(
        false,
    );
    const [isDisableSendNewPolicyNotifications, setIsDisableSendNewPolicyNotifications] = useState<
        boolean
    >(false);
    const [costCenterChoice, setCostCenterChoice] = useState<string>(costCenterOptionsKeys.True);
    const [rules, setRules] = useState<IGroupRule[]>();
    const [standDownPeriod, setStandDownPeriod] = useState<number>(0);
    const [durationPeriod, setDurationPeriod] = useState<number>(1);
    const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[] | undefined>([]);
    const [lifecycleEvent, setLifecycleEvent] = useState<IDropdownOption | undefined>();
    const [selectedDropdownItem, setSelectedDropdownItem] = useState<IDropdownOption>();
    const [selectedRule, setSelectedRule] = useState<IGroupRule | undefined>(undefined);
    const [managerCheckGroup, setManagerCheckGroup] = useState<string>(MemberManagerEnum.Member);
    const [defaultTemplateValue, setDefaultTemplateValue] = useState<string>('');
    const [markdownValue, setMarkdownValue] = useState<string>('');
    const [showCoursePicker, setShowCoursePicker] = useState(false);
    const [allowList, setAllowList] = useState<IPersonaProps[]>([]);
    const [principalAttributeExpression, setPrincipalAttributeExpression] = useState<
        IPrincipalAttributeExpression | undefined
    >();
    const [
        isNewPolicyNotificationConfirmationDialogHidden,
        setisNewPolicyNotificationConfirmationDialogHidden,
    ] = useState<boolean>(true);
    const [
        isInvalidatePriorAgreementCheckDialogHidden,
        setIsInvalidatePriorAgreementCheckDialogHidden,
    ] = useState<boolean>(true);
    const [modalSize, setModalSize] = useState(ModalSizeType.large);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [eligibilityOptions, setEligibilityOptions] = useState<IDropdownOption[] | undefined>(
        undefined,
    );
    const [attributeOptions, setAttributeOptions] = useState<IDropdownOption[] | undefined>(
        undefined,
    );
    const [hasAcknowledgedGracePeriod, setHasAcknowledgedGracePeriod] = useState<boolean>(false);
    const [orgCheckRuleEmployees, setOrgCheckRuleEmployees] = useState<IPersonaProps[]>([]);
    const [uarApproverType, setUarApproverType] = useState<UserAccessReviewApproverType>(
        UserAccessReviewApproverType.Member,
    );
    const [attributes, setAttributes] = useState<GetAttributeResult[]>([]);
    const [isDisabledBulkUarActions, setIsDisabledBulkUarActions] = useState<boolean>(false);
    const [selectedUarAttribute, setSelectedUarAttribute] = useState<IDropdownOption>();
    const dynamicGracePeriodDialogText =
        gracePeriod === 0
            ? 'All members will be non-compliant in your group once you add this rule. You can enforce a grace period to prevent all members from losing access.'
            : `Members will become non-compliant after the ${gracePeriod} day grace period until member re-affirms need to know or owner approves access.`;

    const modalPropsStyles = { main: { maxWidth: 450 } };
    const groupRuleDialogContentProps = {
        type: DialogType.normal,
        title: 'Send New Policy Non-Compliance Notifications',
        subText:
            'Are you sure you would like to send a one-time new policy notification to all grace period and not compliant members of your group?',
    };
    const invalidatePriorAgreementCheckDialogContentProps = {
        type: DialogType.normal,
        title: 'Invalidate Prior Agreements & Attestations',
        subText:
            'All previous agreements and attestations will be invalidated. Group members will be notified and considered non-compliant until new terms have been signed and accepted.',
    };

    const dialogProps = React.useMemo(
        () => ({
            styles: modalPropsStyles,
        }),
        [],
    );

    const managerCheckoptions: IChoiceGroupOption[] = [
        { key: MemberManagerEnum.Member, text: MemberManagerEnum.Member },
        { key: MemberManagerEnum.Manager, text: MemberManagerEnum.Manager },
    ];

    const isNonExternalEnabled = useFeatureFlag(FeatureFlagKeys.enableNonExternalComponents)
        .enabled;

    function clearForm(): void {
        setAttributeOrEligibilityIDs([]);
        setGroupMembershipCheckIDS([]);
        setHRDataCheckCourses([]);
        setTrainingCourses([]);
        setTrainingCoursesFromEdit([]);
        setDescription('');
        setEffectiveStartDate(undefined);
        setEffectiveEndDate(undefined);
        setGracePeriod(0);
        setStandDownPeriod(0);
        setSelectedRule(undefined);
        setManagerCheckGroup(MemberManagerEnum.Member);
        setDefaultTemplateValue('');
        setMarkdownValue('');
        setShowCoursePicker(false);
        setAllowList([]);
        setPolicyRuleName('');
        setIsSuspendPolicyEnforcement(false);
        setIsSuspendPolicyNotifications(false);
        setErrorMessage(undefined);
        setHasAcknowledgedGracePeriod(false);
        setIsSendNewPolicyNotifications(false);
        setOrgCheckRuleEmployees([]);
        setPrincipalAttributeExpression(undefined);
        setUarApproverType(UserAccessReviewApproverType.Member);
        setDurationPeriod(1);
        setLifecycleEvent(undefined);
        setSelectedUarAttribute(undefined);
        setAttributes([]);
        setIsDisableSendNewPolicyNotifications(false);
    }

    useEffect(() => {
        if (props.rules) {
            setRules(props.rules);
            let options = props.rules
                .filter((rule) => {
                    switch (rule.type) {
                        case GroupRuleType.GROUP_MEMBERSHIP_CHECK_RULE:
                            return authContext.isInRole(Role.GroupAdmin);
                        default:
                            return rule;
                    }
                })
                .map((rule) => {
                    return { key: rule.type, text: rule.name };
                });

            if (groupContext.group?.enableDynamic || !groupContext.group?.supportsLegacyRuleTypes) {
                options = options.filter((option) => {
                    return dynamicGroupAvailableRules.has(option.key);
                });
            }
            setDropdownOptions(options);
        }
    }, [props.rules]);

    useEffect(() => {
        const fetchAttributes = async (): Promise<void> => {
            let continuationToken = '';
            const attributesClient = new CoreAttributesClient(authContext);
            const attributes: GetAttributeResult[] = [];
            do {
                const attributesResponse = await attributesClient.getAll(100, continuationToken);
                if (attributesResponse.results && attributesResponse.results.length > 0) {
                    attributes.push(
                        ...attributesResponse.results.filter(
                            (a) =>
                                a.status === AttributeStatus.Active &&
                                a.visibilityLevel === AttributeVisibilityLevel.All,
                        ),
                    );
                }
                continuationToken = attributesResponse.continuationToken ?? '';
            } while (continuationToken);

            setAttributes(attributes);
        };
        // start loading attributes on UAR rule selection to avoid delay
        if (selectedRule?.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE) {
            fetchAttributes();
        }
    }, [selectedRule?.type]);

    const attributeDropDownOptions: IDropdownOption[] = useMemo(() => {
        return attributes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((attribute) => {
                return { key: attribute.id, text: attribute.name };
            });
    }, [attributes]);

    /** @deprecated used for Rule type not supported by principal */
    const fetchAttributes = async (): Promise<void> => {
        try {
            const attributesVar = await EligibilityClient.getAttributes(authContext);

            const propsOptions = attributesVar.map((option) => {
                return { key: option.id, text: option.attributeDesc };
            });
            setAttributeOptions(propsOptions);
        } catch {
            throw 'Unable to fetch policy rule attributes';
        }
    };

    /** @deprecated used for Rule type not supported by principal */
    const fetchEligibilities = async (): Promise<void> => {
        try {
            const eligibilitiesVar = await EligibilityClient.getEligibilities(authContext);

            const propsOptions = eligibilitiesVar.map((option, i) => {
                return { key: option.id, text: option.eligibilityName };
            });
            setEligibilityOptions(propsOptions);
        } catch {
            throw 'Unable to fetch policy rule eligibilities';
        }
    };

    const getIDsFromTrainingCourses = (): string[] => {
        const ids: string[] = [];
        trainingCourses.forEach((course) => {
            ids.push(course.id);
        });
        return ids;
    };

    const onHandleSubmit = async (): Promise<void> => {
        let ruleData: GroupRuleRequestRuleData | undefined;

        if (!selectedRule) {
            throw 'Please select a rule type';
        }

        if (!policyRuleName) {
            throw 'Please select a rule name';
        }

        if (!description) {
            throw 'Please add a description';
        }
        if (
            effectiveStartDate &&
            effectiveEndDate &&
            effectiveStartDate.getTime() >= effectiveEndDate.getTime()
        ) {
            throw 'Effective End Date must be later then Effective Start Date.';
        }

        if (selectedRule?.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE) {
            if (!lifecycleEvent) {
                throw 'Please select a lifecycle event';
            }

            if (lifecycleEvent.key === lifecycleEventOptionsKeys.DurationLifecycle) {
                if (durationPeriod <= 0) {
                    throw 'Please specify a positive duration period';
                }
            }

            if (lifecycleEvent.key === lifecycleEventOptionsKeys.AssignmentChangeLifecycle) {
                if (selectedUarAttribute === undefined) {
                    throw 'Please select an attribute';
                }
            }

            if (
                lifecycleEvent.key === lifecycleEventOptionsKeys.DurationLifecycle &&
                !hasAcknowledgedGracePeriod
            ) {
                throw 'Please acknowledge effect of grace period on membership';
            }
        }

        if (selectedRule?.type === GroupRuleType.ORGANIZATION_CHECK_RULE) {
            if (orgCheckRuleEmployees.length === 0) {
                throw 'Please select at least 1 employee';
            }
        }

        if (selectedRule?.type === GroupRuleType.PRINCIPAL_ATTRIBUTE_CHECK_RULE) {
            if (!principalAttributeExpression) {
                throw 'Please create an attribute expression';
            }
        }

        switch (selectedRule.type) {
            case GroupRuleType.ELIGIBILITY_CHECK_RULE:
                ruleData = {
                    eligibilityIds: attributeOrEligibilityIDs,
                };
                break;
            case GroupRuleType.ATTRIBUTE_CHECK_RULE:
                ruleData = {
                    attributeIds: attributeOrEligibilityIDs,
                };
                break;
            case GroupRuleType.US_CITIZENSHIP_CHECK_RULE:
            case GroupRuleType.CLOUD_SCREENING_CHECK_RULE:
            case GroupRuleType.FTE_CHECK_RULE:
                ruleData = {
                    managerCheck: managerCheckGroup === 'Manager',
                };
                break;
            case GroupRuleType.GROUP_MEMBERSHIP_CHECK_RULE:
                ruleData = { groupIds: groupMembershipCheckIDS };
                break;
            case GroupRuleType.HR_DATA_CHECK_RULE:
                if (HRMetaDataType === EmployeeMetadataType.CompanyCode) {
                    ruleData = {
                        field: HRMetaDataType,
                        companyCodes: HRDataCheckCourses,
                    };
                }
                if (HRMetaDataType === EmployeeMetadataType.CountryCode) {
                    ruleData = {
                        field: HRMetaDataType,
                        countryCodes: HRDataCheckCourses,
                    };
                }
                if (HRMetaDataType === EmployeeMetadataType.CostCenterCode) {
                    ruleData = {
                        field: HRMetaDataType,
                        costCenterCodes: HRDataCheckCourses,
                        evaluationType:
                            costCenterChoice === costCenterOptionsKeys.True
                                ? costCenterOptionsEvaluationType.Inclusive
                                : costCenterOptionsEvaluationType.Exclusive,
                    };
                }
                break;
            case GroupRuleType.STAND_DOWN_CHECK_RULE:
                ruleData = { standDownDays: standDownPeriod };
                break;
            case GroupRuleType.TRAINING_CHECK_RULE:
                ruleData = {
                    courseIds: getIDsFromTrainingCourses(),
                };
                break;
            case GroupRuleType.CA_NDA_CHECK_RULE:
                ruleData = {
                    CANDAText: markdownValue,
                    invalidatePriorAgreementCheck: isInvalidatePriorAgreementChecked,
                };
                break;
            case GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE:
                if (lifecycleEvent?.key === lifecycleEventOptionsKeys.DurationLifecycle) {
                    ruleData = {
                        CANDAText: markdownValue,
                        durationDays: durationPeriod,
                        subtype: lifecycleEventOptionsKeys.DurationLifecycle,
                        approverType: uarApproverType,
                        invalidatePriorAgreementCheck: isInvalidatePriorAgreementChecked,
                        disableBulkUarActions: isDisabledBulkUarActions,
                    };
                } else if (
                    lifecycleEvent?.key === lifecycleEventOptionsKeys.AssignmentChangeLifecycle
                ) {
                    ruleData = {
                        CANDAText: '',
                        attributeId: selectedUarAttribute?.key as string,
                        subtype: lifecycleEventOptionsKeys.AssignmentChangeLifecycle,
                        approverType: uarApproverType,
                        invalidatePriorAgreementCheck: isInvalidatePriorAgreementChecked,
                        disableBulkUarActions: isDisabledBulkUarActions,
                        attributeName: selectedUarAttribute?.text as string,
                    };
                }
                break;
            case GroupRuleType.ORGANIZATION_CHECK_RULE:
                ruleData = {
                    employeeIds: getPrincipalRecordsFromPersonas(orgCheckRuleEmployees).map(
                        (x) => x.id,
                    ),
                };
                break;
            case GroupRuleType.PRINCIPAL_ATTRIBUTE_CHECK_RULE:
                ruleData = principalAttributeExpression;
                if (ruleData && ruleData.children) {
                    ruleData.children.forEach((child) => {
                        if (!validatePolicyRuleExpression(child)) {
                            throw 'Principal attribute rule expression is invalid. Please ensure each expression has a valid attribute set, attribute, operation, and value.';
                        }
                    });
                }
                break;
            default:
                ruleData = undefined;
        }
        if (ruleData) {
            await onHandleFinalSubmit(ruleData);
        }
    };

    const onHandleFinalSubmit = async (ruleData: any): Promise<void> => {
        if (groupContext.group && selectedRule && policyRuleName && description) {
            try {
                if (props.isEditing && props.ruleData) {
                    const result = await GroupClient.updateGroupRule(
                        authContext,
                        groupContext.group?.id,
                        props.ruleData?.id,
                        {
                            allowList: getPrincipalRecordsFromPersonas(allowList).map((x) => x.id),
                            description: description,
                            effectiveStartTimestampUTC:
                                effectiveStartDate !== undefined
                                    ? effectiveStartDate.getTime() / 1000
                                    : null,
                            effectiveEndTimestampUTC:
                                effectiveEndDate !== undefined
                                    ? effectiveEndDate.getTime() / 1000
                                    : null,
                            gracePeriod: gracePeriod !== undefined ? gracePeriod.toString() : '0',
                            metadata: null,
                            name: policyRuleName,
                            optOutFromPolicyViolationNotifications: isSuspendPolicyNotifications,
                            ruleData: ruleData,
                            standDownPeriodDays: standDownPeriod,
                            suspended: isSuspendPolicyEnforcement,
                            type: selectedRule?.type,
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            _etag: '',
                        },
                    );

                    if (props.updateListAfterEdit) {
                        props.updateListAfterEdit(result);
                    }
                } else {
                    await GroupClient.addGroupRule(authContext, groupContext.group?.id, {
                        allowList: getPrincipalRecordsFromPersonas(allowList).map((x) => x.id),
                        description: description,
                        effectiveStartTimestampUTC:
                            effectiveStartDate !== undefined
                                ? effectiveStartDate.getTime() / 1000
                                : null,
                        effectiveEndTimestampUTC:
                            effectiveEndDate !== undefined
                                ? effectiveEndDate.getTime() / 1000
                                : null,
                        enableNewPolicyMessage: isSendNewPolicyNotifications,
                        gracePeriod: gracePeriod !== undefined ? gracePeriod.toString() : '0',
                        metadata: null,
                        name: policyRuleName,
                        optOutFromPolicyViolationNotifications: isSuspendPolicyNotifications,
                        ruleData: ruleData,
                        suspended: isSuspendPolicyEnforcement,
                        type: selectedRule?.type,
                        standDownPeriodDays: standDownPeriod,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        _etag: '',
                    });
                }
                setSelectedRule(undefined);
                setSelectedDropdownItem(undefined);
                if (props.updatePolicyRulesTableAfterEdit) {
                    props.updatePolicyRulesTableAfterEdit();
                }
            } catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setErrorMessage(e as any);
                throw e;
            }
        }
    };

    const onHandleAcknowledgeGracePeriod = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setHasAcknowledgedGracePeriod(!!checked);
        },
        [],
    );

    const onHandleSuspendPolicyEnforcement = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsSuspendPolicyEnforcement(!!checked);
        },
        [],
    );

    const onHandleSuspendPolicyNotifications = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsSuspendPolicyNotifications(!!checked);
        },
        [],
    );

    const onHandleSendNewPolicyNotifications = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            if (isSendNewPolicyNotifications === false && checked) {
                setisNewPolicyNotificationConfirmationDialogHidden(false);
            } else {
                setIsSendNewPolicyNotifications(false);
            }
        },
        [],
    );

    const onHandleisInvalidatePriorAgreementChecked = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            if (isInvalidatePriorAgreementChecked === false && checked) {
                setIsInvalidatePriorAgreementCheckDialogHidden(false);
            } else {
                setIsInvalidatePriorAgreementChecked(false);
            }
        },
        [],
    );

    const onHandleNewPolicyNotificationConfirmation = (): void => {
        setIsSendNewPolicyNotifications(true);
        setisNewPolicyNotificationConfirmationDialogHidden(true);
    };

    const onHandleinvalidatePriorAgreementConfirmation = (): void => {
        setIsInvalidatePriorAgreementChecked(true);
        setIsInvalidatePriorAgreementCheckDialogHidden(true);
    };

    const onSelectLifecycleEvent = (
        event: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption | undefined,
    ): void => {
        if (item) {
            setLifecycleEvent(item);
        }
    };

    const onManagercheckGroupChange = (
        ev: React.SyntheticEvent<HTMLElement> | undefined,
        option: IChoiceGroupOption | undefined,
    ): void => {
        if (ev?.type === 'change' && option !== undefined) {
            setManagerCheckGroup(
                option.key === MemberManagerEnum.Manager
                    ? MemberManagerEnum.Manager
                    : MemberManagerEnum.Member,
            );
            if (selectedRule?.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE) {
                switch (option.key) {
                    case MemberManagerEnum.Manager:
                        setUarApproverType(UserAccessReviewApproverType.Manager);
                        setIsSendNewPolicyNotifications(true);
                        setIsDisableSendNewPolicyNotifications(true);
                        break;
                    case MemberManagerEnum.Member:
                        setUarApproverType(UserAccessReviewApproverType.Member);
                        setIsDisableSendNewPolicyNotifications(false);
                        break;
                }
            }
        }
    };

    const onChangeDefaultTemplateValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setDefaultTemplateValue(newValue || '');
            setMarkdownValue(
                newValue?.replaceAll(
                    '{{GroupName}}',
                    groupContext?.group?.name || 'GROUP_NAME_UNDEFINED',
                ) || '',
            );
        },
        [],
    );

    const renderMarkdownPreview = (): boolean => {
        switch (selectedRule?.type) {
            case GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE:
                if (
                    lifecycleEvent?.key !== undefined &&
                    lifecycleEvent?.key !== lifecycleEventOptionsKeys.AssignmentChangeLifecycle
                ) {
                    return true;
                } else {
                    return false;
                }
            case GroupRuleType.CA_NDA_CHECK_RULE:
                return true;
            default:
                return false;
        }
    };

    const renderManagerCheckChoiceGroup = (): boolean => {
        switch (selectedRule?.type) {
            case GroupRuleType.US_CITIZENSHIP_CHECK_RULE:
            case GroupRuleType.CLOUD_SCREENING_CHECK_RULE:
            case GroupRuleType.FTE_CHECK_RULE:
                return true;
            default:
                return false;
        }
    };

    const renderInvalidatePriorAgreementCheck = (): boolean => {
        if (props.isEditing) {
            if (
                selectedRule?.type === GroupRuleType.CA_NDA_CHECK_RULE ||
                (selectedRule?.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE &&
                    lifecycleEvent?.key === lifecycleEventOptionsKeys.DurationLifecycle)
            ) {
                return true;
            }
        }
        return false;
    };

    const onDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption | undefined,
    ): void => {
        if (item) {
            clearForm();
            const foundRule = props.rules?.filter((rule) => rule.type === item.key);

            if (foundRule) {
                setSelectedRule(foundRule[0]);
                setSelectedDropdownItem(item);
                setDescription(foundRule[0].description);

                if (item.key === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE) {
                    setDefaultTemplateValue(foundRule[0].metadata.defaultText);
                    setMarkdownValue(
                        foundRule[0].metadata.defaultText.replaceAll(
                            '{{GroupName}}',
                            groupContext?.group?.name || 'GROUP_NAME_UNDEFINED',
                        ),
                    );
                    setGracePeriod(30);
                }

                if (item.key === GroupRuleType.CA_NDA_CHECK_RULE) {
                    setDefaultTemplateValue(foundRule[0].metadata.defaultText);
                    setMarkdownValue(
                        foundRule[0].metadata.defaultText.replaceAll(
                            '{{GroupName}}',
                            groupContext?.group?.name || 'GROUP_NAME_UNDEFINED',
                        ),
                    );
                }
            }
        }
    };

    const onHandleDescriptionChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setDescription(newValue || '');
        },
        [],
    );

    const onHandlePolicyRuleNameChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setPolicyRuleName(newValue || '');
        },
        [],
    );

    const onHandleUpdateAllowList = async (allowList: string[]): Promise<void> => {
        if (groupContext.group) {
            try {
                // Need to insure use with no 'global roles' can still get back data
                const initialAllowList = await obtainPrincipalRecords({
                    principalClient: new CorePrincipalsClient(authContext),
                    principalIds: allowList,
                });
                const initialAllowListPersonas = getPersonasFromPrincipalRecords(initialAllowList);
                setAllowList(initialAllowListPersonas);
            } catch (e) {
                console.error(e);
                throw 'Unable to edit policy rule';
            }
        }
    };

    const onHandleSearchCourses = async (courseIds?: string[]): Promise<void> => {
        try {
            if (courseIds) {
                const courseDetails = await GroupClient.getCourseDetails(authContext, courseIds);

                setShowCoursePicker(true);
                setTrainingCoursesFromEdit(courseDetails);
                setTrainingCourses(courseDetails);
            }
        } catch {
            throw 'Unable to find existing courses';
        }
    };

    const onHandleUpdateOrgCheckRuleEmployees = async (
        orgCheckRuleEmployees: string[],
    ): Promise<void> => {
        if (groupContext.group) {
            try {
                // Need to insure use with no 'global roles' can still get back data
                const initialOrgCheckRuleEmployees = await obtainPrincipalRecords({
                    principalClient: new CorePrincipalsClient(authContext),
                    principalIds: orgCheckRuleEmployees,
                });

                setOrgCheckRuleEmployees(
                    getPersonasFromPrincipalRecords(initialOrgCheckRuleEmployees),
                );
            } catch (e) {
                console.error(e);
                throw 'Unable to edit policy rule';
            }
        }
    };
    const onActionButtonClick = (): void => {
        toggleFetch();
        clearForm();
        if (props.isEditing && dropdownOptions && dropdownOptions?.length > 0) {
            const foundOption = dropdownOptions?.filter((option) => {
                return option.key === props.ruleData?.type;
            });
            const foundRule = rules?.filter((rule) => rule.type === props.ruleData?.type);

            if (foundOption && foundRule) {
                setSelectedRule(foundRule[0]);
                setSelectedDropdownItem(foundOption[0]);
                setDescription(props.ruleData?.description);
                setPolicyRuleName(props.ruleData?.name);
                if (props.ruleData?.type === GroupRuleType.STAND_DOWN_CHECK_RULE) {
                    const standDownRuleData = props.ruleData as IStandDownCheckRule;
                    setStandDownPeriod(standDownRuleData.standDownPeriodDays || 0);
                }
                if (props.ruleData) {
                    /* eslint-disable */
                    // linter complained about var names sent from backend
                    const {
                        allowList,
                        attributeIds,
                        CANDAText,
                        companyCodes,
                        costCenterCodes,
                        countryCodes,
                        courseIds,
                        durationDays,
                        eligibilityIds,
                        employeeIds,
                        effectiveStartTimestampUTC,
                        effectiveEndTimestampUTC,
                        groupIds,
                        managerCheck,
                        gracePeriod,
                        optOutFromPolicyViolationNotifications,
                        suspended,
                        attributeExpression,
                        attributeId,
                        subtype,
                        disableBulkUarActions,
                        attributeName,
                    } = props.ruleData;
                    /* eslint-enable */

                    setUarApproverType(
                        props.ruleData?.approverType || UserAccessReviewApproverType.Member,
                    );

                    setGracePeriod(gracePeriod);
                    setIsSuspendPolicyNotifications(optOutFromPolicyViolationNotifications);
                    setIsSuspendPolicyEnforcement(suspended);

                    if (attributeIds) {
                        setAttributeOrEligibilityIDs(attributeIds);
                    }
                    if (attributeExpression) {
                        setPrincipalAttributeExpression(
                            generatePrincipalExpressionIds(attributeExpression),
                        );
                    }
                    if (eligibilityIds) {
                        setAttributeOrEligibilityIDs(eligibilityIds);
                    }

                    if (effectiveStartTimestampUTC) {
                        const startDate = new Date(effectiveStartTimestampUTC * 1000);
                        setEffectiveStartDate(startDate);
                    }

                    if (effectiveEndTimestampUTC) {
                        const endDate = new Date(effectiveEndTimestampUTC * 1000);
                        setEffectiveEndDate(endDate);
                    }

                    setManagerCheckGroup(
                        !managerCheck ? MemberManagerEnum.Member : MemberManagerEnum.Manager,
                    );

                    if (allowList && allowList.length > 0) {
                        onHandleUpdateAllowList(allowList);
                    }

                    if (courseIds) {
                        onHandleSearchCourses(courseIds);
                    }

                    if (companyCodes) {
                        setHRMetaDataType(EmployeeMetadataType.CompanyCode);
                        setHRDataCheckCourses(companyCodes);
                    }

                    if (countryCodes) {
                        setHRMetaDataType(EmployeeMetadataType.CountryCode);
                        setHRDataCheckCourses(countryCodes);
                    }

                    if (costCenterCodes) {
                        setHRMetaDataType(EmployeeMetadataType.CostCenterCode);
                        setHRDataCheckCourses(costCenterCodes);
                    }

                    if (CANDAText) {
                        setDefaultTemplateValue(CANDAText.text);
                        setMarkdownValue(CANDAText.text);
                        setIsInvalidatePriorAgreementChecked(
                            CANDAText.invalidatePriorAgreementCheck,
                        );

                        if (durationDays) {
                            setLifecycleEvent(lifecycleEventOptions[0]);
                            setDurationPeriod(durationDays);
                        }
                    }

                    if (groupIds) {
                        setGroupMembershipCheckIDS(groupIds);
                    }

                    if (employeeIds && employeeIds.length > 0) {
                        onHandleUpdateOrgCheckRuleEmployees(employeeIds);
                    }

                    if (subtype === lifecycleEventOptionsKeys.AssignmentChangeLifecycle) {
                        setLifecycleEvent(lifecycleEventOptions[1]);
                    }

                    if (attributeId && attributeName) {
                        setSelectedUarAttribute({
                            key: attributeId,
                            text: attributeName,
                        } as IDropdownOption);
                    }

                    if (disableBulkUarActions) {
                        setIsDisabledBulkUarActions(disableBulkUarActions);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (selectedRule?.type === GroupRuleType.ATTRIBUTE_CHECK_RULE) {
            fetchAttributes();
        }
        if (selectedRule?.type === GroupRuleType.ELIGIBILITY_CHECK_RULE) {
            fetchEligibilities();
        }
        if (
            selectedRule?.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE ||
            selectedRule?.type === GroupRuleType.CA_NDA_CHECK_RULE
        ) {
            setModalSize(ModalSizeType.xxLarge);
        } else if (selectedRule?.type === GroupRuleType.PRINCIPAL_ATTRIBUTE_CHECK_RULE) {
            setModalSize(ModalSizeType.size1400);
        } else {
            setModalSize(ModalSizeType.large);
        }
    }, [selectedRule]);

    return (
        <ModalActionButton
            text={props.isEditing ? 'Edit' : 'Create Policy Rule'}
            size={modalSize}
            fixWidth={true}
            onButtonClick={onActionButtonClick}
            enable={groupContext.isOwner() || authContext.isInRole(Role.GroupAdmin)}
            onCancel={(): void => {
                setSelectedRule(undefined);
                setSelectedDropdownItem(undefined);
            }}
            iconName={props.isEditing ? IconNames.Edit : IconNames.Add}
            modalTitle={props.isEditing ? 'Edit Policy Rule' : 'Create Policy Rule'}
            shouldHideCancelButton={false}
            errorMsg={errorMessage}
            enableSubmit={selectedRule?.type !== GroupRuleType.LOCATION_CHECK_RULE}
            submitButtonText='Submit'
            onSubmit={onHandleSubmit}
            onModalConcluded={doNothing}>
            <Dialog
                hidden={isNewPolicyNotificationConfirmationDialogHidden}
                dialogContentProps={groupRuleDialogContentProps}
                modalProps={dialogProps}>
                <DialogFooter>
                    <DefaultButton
                        onClick={(): void =>
                            setisNewPolicyNotificationConfirmationDialogHidden(true)
                        }
                        text='Cancel'
                    />
                    <PrimaryButton
                        onClick={onHandleNewPolicyNotificationConfirmation}
                        text='Confirm'
                    />
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={isInvalidatePriorAgreementCheckDialogHidden}
                dialogContentProps={invalidatePriorAgreementCheckDialogContentProps}
                modalProps={dialogProps}>
                <DialogFooter>
                    <DefaultButton
                        onClick={(): void => setIsInvalidatePriorAgreementCheckDialogHidden(true)}
                        text='Cancel'
                    />
                    <PrimaryButton
                        onClick={onHandleinvalidatePriorAgreementConfirmation}
                        text='Confirm'
                    />
                </DialogFooter>
            </Dialog>

            <div className={styles.flexDualContainerLayout}>
                {dropdownOptions && props.rules && (
                    <Dropdown
                        label='Type'
                        placeholder='Please select a rule type'
                        disabled={props.isEditing}
                        required={true}
                        onChange={onDropdownChange}
                        options={dropdownOptions}
                        selectedKey={selectedDropdownItem ? selectedDropdownItem.key : undefined}
                    />
                )}

                <TextField
                    label='Name'
                    value={policyRuleName || ''}
                    onChange={onHandlePolicyRuleNameChange}
                    required={true}
                    resizable={false}
                />
            </div>
            {selectedRule?.type === GroupRuleType.LOCATION_CHECK_RULE && (
                <>
                    <MessageBar messageBarType={MessageBarType.error}>
                        The Location Check Rule is deprecated. Please use the new HR Data Check Rule
                        with the Country Code field.
                    </MessageBar>
                    <Spacer marginTop={10} />
                </>
            )}
            <TextField
                label='Description'
                value={description || ''}
                onChange={onHandleDescriptionChange}
                required={true}
                multiline={true}
                resizable={false}
                rows={5}
            />
            <Spacer marginTop={10} />
            {selectedRule &&
                selectedRule?.type === GroupRuleType.ATTRIBUTE_CHECK_RULE &&
                attributeOptions && (
                    <OptionSelectDisplay
                        type={AttributeOrEligibility.attribute}
                        dropDownOptions={attributeOptions}
                        setChosenIDs={setAttributeOrEligibilityIDs}
                        chosenValue={selectedRule}
                        attributeOrEligibilityIDs={attributeOrEligibilityIDs}
                        isEditing={props.isEditing}
                        ariaLabel='attribute options'
                    />
                )}
            {selectedRule &&
                selectedRule?.type === GroupRuleType.ELIGIBILITY_CHECK_RULE &&
                eligibilityOptions && (
                    <OptionSelectDisplay
                        type={AttributeOrEligibility.eligibility}
                        dropDownOptions={eligibilityOptions}
                        setChosenIDs={setAttributeOrEligibilityIDs}
                        chosenValue={selectedRule}
                        attributeOrEligibilityIDs={attributeOrEligibilityIDs}
                        isEditing={props.isEditing}
                        ariaLabel='eligibility options'
                    />
                )}
            <Spacer marginTop={10} />
            {selectedRule?.type === GroupRuleType.GROUP_MEMBERSHIP_CHECK_RULE && (
                <GroupMembershipRulePicker
                    setGroupMembershipCheckIDS={setGroupMembershipCheckIDS}
                    groupMembershipCheckIDS={groupMembershipCheckIDS}
                    isEditing={props.isEditing}
                />
            )}
            {selectedRule?.type === GroupRuleType.STAND_DOWN_CHECK_RULE && (
                <div className={styles.standDownRuleContainer}>
                    <TextField
                        label='Stand-down Period'
                        type='number'
                        required={true}
                        value={standDownPeriod.toString()}
                        onChange={(
                            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                            newValue,
                        ): void => {
                            if (newValue) setStandDownPeriod(parseInt(newValue));
                        }}
                    />
                    <span className={styles.standDownSpan}>days</span>
                </div>
            )}
            {selectedRule?.type === GroupRuleType.HR_DATA_CHECK_RULE && (
                <CountryOrCompanyCodePickerDisplay
                    HRDataCheckCourses={HRDataCheckCourses}
                    setHRDataCheckCourses={setHRDataCheckCourses}
                    setCostCenterChoice={setCostCenterChoice}
                    costCenterChoice={costCenterChoice}
                    setHRMetaDataType={setHRMetaDataType}
                    HRMetaDataType={HRMetaDataType}
                    isEditing={props.isEditing}
                />
            )}
            {selectedRule?.type === GroupRuleType.TRAINING_CHECK_RULE && (
                <p>
                    Member must have <b>any</b> of the following courses:
                </p>
            )}
            {!showCoursePicker && selectedRule?.type === GroupRuleType.TRAINING_CHECK_RULE && (
                <>
                    <a
                        className={globalStyles.link}
                        onClick={(): void => setShowCoursePicker(true)}>
                        Add Course...
                    </a>
                    <Spacer marginTop={15} />
                </>
            )}
            {selectedRule && showCoursePicker && (
                <>
                    <CoursePickerDisplay
                        setTrainingCourses={setTrainingCourses}
                        trainingCourses={trainingCourses}
                        trainingCoursesFromEdit={trainingCoursesFromEdit}
                        isEditing={props.isEditing}
                    />
                    <Spacer marginTop={18} />
                </>
            )}
            {selectedRule?.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE && (
                <div>
                    <Stack horizontal={true} horizontalAlign='space-between'>
                        <Stack.Item className={styles.lifeCycle}>
                            <Dropdown
                                label='Lifecycle Event'
                                placeholder='Please select a lifecycle event'
                                required={true}
                                onChange={onSelectLifecycleEvent}
                                options={lifecycleEventOptions}
                                selectedKey={lifecycleEvent ? lifecycleEvent.key : undefined}
                                disabled={props.isEditing}
                            />
                        </Stack.Item>

                        {lifecycleEvent?.key === lifecycleEventOptionsKeys.DurationLifecycle && (
                            <Stack.Item className={styles.lifeCycle}>
                                <TextField
                                    label='Duration Period'
                                    type='number'
                                    required={true}
                                    min={1}
                                    value={durationPeriod.toString()}
                                    onChange={(
                                        event: React.FormEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >,
                                        newValue,
                                    ): void => {
                                        if (newValue) setDurationPeriod(parseInt(newValue));
                                    }}
                                />
                                <Spacer marginTop={10} />
                            </Stack.Item>
                        )}
                        {lifecycleEvent?.key ===
                            lifecycleEventOptionsKeys.AssignmentChangeLifecycle && (
                            <Stack.Item className={styles.lifeCycle}>
                                <Dropdown
                                    label='Attribute'
                                    placeholder='Please select an attribute'
                                    required={true}
                                    onChange={(ev, opt) => {
                                        setSelectedUarAttribute(opt);
                                    }}
                                    selectedKey={
                                        selectedUarAttribute?.key
                                            ? selectedUarAttribute?.key
                                            : undefined
                                    }
                                    options={attributeDropDownOptions}
                                    disabled={props.isEditing}
                                />
                                <Spacer marginTop={10} />
                            </Stack.Item>
                        )}
                    </Stack>

                    <Stack horizontal={true} horizontalAlign='space-between'>
                        <Stack.Item style={{ marginBottom: '10px' }} className={styles.lifeCycle}>
                            <ChoiceGroup
                                defaultSelectedKey='Member'
                                options={EnabledUserAccessReviewApproverTypes.map((option) => {
                                    return {
                                        key: option,
                                        text: separateWordsByCapitalLetter(option),
                                    };
                                })}
                                label='Approver'
                                className={styles.choiceGroup}
                                required={true}
                                onChange={(ev, opt) =>
                                    setUarApproverType(
                                        (opt?.key as UserAccessReviewApproverType) ??
                                            UserAccessReviewApproverType.Member,
                                    )
                                }
                                selectedKey={uarApproverType.toString()}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal={true}>
                        <Stack.Item>
                            <Label styles={{ root: { marginTop: '2px' } }}>
                                Disable Bulk UAR Actions
                            </Label>
                        </Stack.Item>
                        <Stack.Item>
                            <TooltipHost
                                content={
                                    'This action will prevent bulk UAR approvals and denials from the action center and will require approvers to read and acknowledge the individual rule'
                                }>
                                <ActionButton
                                    styles={{
                                        icon: { color: 'black' },
                                    }}
                                    iconProps={{
                                        iconName: IconNames.Info,
                                    }}></ActionButton>
                            </TooltipHost>
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal={true}>
                        <Stack.Item>
                            <Checkbox
                                label='Require approver to read and acknowledge the individual rule.'
                                checked={isDisabledBulkUarActions}
                                onChange={() =>
                                    setIsDisabledBulkUarActions(!isDisabledBulkUarActions)
                                }
                            />
                        </Stack.Item>
                    </Stack>
                </div>
            )}
            {selectedRule?.type === GroupRuleType.ORGANIZATION_CHECK_RULE && (
                <>
                    <Spacer marginTop={10} />
                    <Label required={true} className={styles.orgCheckRuleEmployeeLabel}>
                        Member must have <b>any</b> of the following people in their hierarchy:
                    </Label>
                    <CoreMultiPrincipalPersonaPickerTypeaheadSearch
                        ariaLabel='Member must have any of the following people in their hierarchy'
                        required={true}
                        itemLimit={EmployeeListPickerUnlimited}
                        placeHolder='Employee Name or Alias'
                        selectedItems={orgCheckRuleEmployees}
                        onChange={(items): void => {
                            if (items) {
                                setOrgCheckRuleEmployees(items);
                            } else {
                                setOrgCheckRuleEmployees([]);
                            }
                        }}
                    />
                </>
            )}
            {renderMarkdownPreview() && (
                <div className={styles.markdownComponentWrapper}>
                    <TextField
                        value={defaultTemplateValue}
                        label={
                            selectedRule?.type === GroupRuleType.CA_NDA_CHECK_RULE
                                ? 'Agreement & Attestation Rule Text'
                                : 'Contract Text'
                        }
                        multiline={true}
                        required={true}
                        onChange={onChangeDefaultTemplateValue}
                        resizable={false}
                    />

                    <div className={styles.markdownPreviewWrapper}>
                        <label>Markdown Preview</label>
                        <div className={styles.markdownPreview}>
                            {
                                <ReactMarkdown
                                    // eslint-disable-next-line react/no-children-prop
                                    children={markdownValue}
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                    aria-label='Markdown Preview'
                                />
                            }
                        </div>
                    </div>
                </div>
            )}
            {renderInvalidatePriorAgreementCheck() && (
                <>
                    <Spacer marginTop={20} />
                    <Checkbox
                        label='Invalidate prior agreement'
                        checked={isInvalidatePriorAgreementChecked}
                        onChange={onHandleisInvalidatePriorAgreementChecked}
                    />
                    <Spacer marginTop={10} />
                </>
            )}
            {renderManagerCheckChoiceGroup() && (
                <>
                    <ChoiceGroup
                        aria-label='Manager check choice group'
                        defaultSelectedKey='Member'
                        options={managerCheckoptions}
                        onChange={onManagercheckGroupChange}
                        required={true}
                        className={styles.choiceGroup}
                    />
                    <Spacer marginTop={10} />
                </>
            )}
            <Spacer marginTop={10} />
            <label className={styles.allowListLabel}>Allow List</label>
            <CoreMultiPrincipalPersonaPickerTypeaheadSearch
                ariaLabel='Allow List'
                itemLimit={EmployeeListPickerUnlimited}
                placeHolder='Search Employees'
                selectedItems={allowList}
                onChange={(items): void => {
                    if (items) {
                        setAllowList(items);
                    } else {
                        setAllowList([]);
                    }
                }}
            />
            <Spacer marginTop={15} />
            <div className={styles.flexDualContainerLayout}>
                <DatePicker
                    onSelectDate={(newDate?: Date | null | undefined): void => {
                        if (newDate) {
                            setEffectiveStartDate(newDate);
                        }
                    }}
                    label='Effective Start Date'
                    value={effectiveStartDate}
                    allowTextInput={true}
                    minDate={addDays(new Date(Date.now()), 0)}
                />
                <DatePicker
                    onSelectDate={(newDate?: Date | null | undefined): void => {
                        if (newDate) {
                            setEffectiveEndDate(newDate);
                        }
                    }}
                    label='Effective End Date'
                    value={effectiveEndDate}
                    allowTextInput={true}
                    minDate={effectiveStartDate}
                />
            </div>
            <DefaultButton
                text='Clear Date fields'
                onClick={(): void => {
                    setEffectiveStartDate(undefined);
                    setEffectiveEndDate(undefined);
                }}
            />
            <Spacer marginTop={15} />
            <div className={styles.gracePeriod}>
                <TextField
                    label='Grace Period (in days)'
                    defaultValue='0'
                    min={`0`}
                    value={gracePeriod?.toString() || ''}
                    onChange={(
                        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                        newValue,
                    ): void => {
                        if (newValue) {
                            setGracePeriod(parseInt(newValue));
                            if (
                                selectedRule?.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE
                            ) {
                                // Since the variable gracePeriod affects the acknowledgement
                                // text, force the user to re-acknowledge if they change it.
                                setHasAcknowledgedGracePeriod(false);
                            }
                        }
                    }}
                    type='number'
                />
            </div>

            <Checkbox
                label='Suspend Policy Enforcement'
                checked={isSuspendPolicyEnforcement}
                onChange={onHandleSuspendPolicyEnforcement}
            />
            <Spacer marginTop={15} />
            {isNonExternalEnabled && !groupContext.group?.enableHidden && (
                <Checkbox
                    label='Suspend Policy Notifications'
                    checked={isSuspendPolicyNotifications}
                    onChange={onHandleSuspendPolicyNotifications}
                />
            )}
            <Spacer marginTop={15} />
            {isNonExternalEnabled && !props.isEditing && !groupContext.group?.enableHidden && (
                <Checkbox
                    label='Send New Policy Notifications'
                    checked={isSendNewPolicyNotifications}
                    onChange={onHandleSendNewPolicyNotifications}
                    disabled={isDisableSendNewPolicyNotifications}
                />
            )}
            {selectedRule?.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE &&
                lifecycleEvent?.key === lifecycleEventOptionsKeys.DurationLifecycle && (
                    <>
                        <Spacer marginTop={15} />
                        <Label required>Please Acknowledge</Label>
                        <Checkbox
                            label={dynamicGracePeriodDialogText}
                            checked={hasAcknowledgedGracePeriod}
                            onChange={onHandleAcknowledgeGracePeriod}
                        />
                    </>
                )}
            {selectedRule && selectedRule?.type === GroupRuleType.PRINCIPAL_ATTRIBUTE_CHECK_RULE && (
                <div style={{ marginTop: '25px' }}>
                    <Label>Build policy rule expression</Label>
                    <AttributePolicyBuilder
                        expressions={principalAttributeExpression?.children ?? []}
                        onExpressionsChanged={(expressions): void => {
                            setPrincipalAttributeExpression({
                                id: 'parent',
                                children: expressions,
                            });
                        }}
                    />
                </div>
            )}
        </ModalActionButton>
    );
}
const styles = mergeStyleSets({
    choiceGroup: {
        selectors: {
            '.ms-ChoiceFieldGroup-flexContainer': {
                display: 'flex',
            },
            '.ms-ChoiceField': {
                marginRight: 15,
            },
        },
    },
    uarChoiceGroup: {
        width: '53%',
        marginBottom: 20,
    },
    flexDualContainerLayout: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 20,
        selectors: {
            '.ms-DatePicker, .ms-TextField, .ms-Dropdown-container': {
                width: sideBySideComponentWidth,
            },
            '.ms-DatePicker .ms-TextField': {
                width: '100%',
            },
        },
    },
    gracePeriod: {
        width: sideBySideComponentWidth,
        marginBottom: 30,
    },
    lifeCycle: {
        width: sideBySideComponentWidth,
        marginBottom: 20,
    },
    allowListLabel: {
        paddingTop: 5,
        paddingBottom: 5,
        color: NeutralColors.gray160,
        fontWeight: 600,
        display: 'block',
    },
    standDownRuleContainer: {
        display: 'flex',
        marginBottom: 10,
        alignItems: 'flex-end',
    },
    standDownSpan: {
        marginLeft: 10,
        marginBottom: 5,
    },
    markdownComponentWrapper: {
        marginTop: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        selectors: {
            '.ms-TextField': {
                width: sideBySideComponentWidth,
            },
            '.ms-TextField-field': {
                height: 200,
            },
        },
    },
    markdownPreviewWrapper: {
        width: sideBySideComponentWidth,
        paddingTop: 5,
        selectors: {
            'label': {
                paddingTop: 5,
                paddingBottom: 5,
                color: NeutralColors.gray160,
                fontWeight: 600,
            },
            'div': {
                overflow: 'scroll',
                padding: '6px 8px',
                height: 190,
            },
        },
    },
    markdownPreview: {
        border: `1px solid ${NeutralColors.gray130}`,
        minHeight: '60px',
        height: 200,
        marginTop: 5,
        padding: '3px 4px',
        selectors: {
            'p': {
                margin: '0 0 10px 0',
            },
        },
    },
    orgCheckRuleEmployeeLabel: {
        paddingTop: 5,
        paddingBottom: 5,
        fontWeight: 400,
        color: NeutralColors.gray160,
        display: 'block',
    },
});

function generatePrincipalExpressionIds(
    ex: IPrincipalAttributeExpression,
): IPrincipalAttributeExpression {
    if (!ex.id) {
        ex.id = crypto.randomUUID();
    }

    if (ex.children) {
        ex.children = ex.children.map((x) => generatePrincipalExpressionIds(x));
    }

    return ex;
}
function validatePolicyRuleExpression(child: IPrincipalAttributeExpression): boolean {
    if (child.children && Array.isArray(child.children)) {
        // Validate each child in the children array
        for (const subChild of child.children) {
            if (!validatePolicyRuleExpression(subChild)) {
                return false; // If any sub-child fails validation, return false
            }
        }
    } else {
        // Perform the usual validation for child without children
        if (child.operation === undefined || child.operation === null) {
            return false;
        }

        if (child.value === undefined || child.value === null || child.value === '') {
            return false;
        }
    }
    return true;
}
