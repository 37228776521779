import { Stack } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import GroupClient, {
    IApplicationGroupMembership,
    IGroup,
    IGroupMembership,
    IPointOfContact,
    PocTypeEnum,
} from 'clients/group-client';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import Spacer from 'components/common/spacer';
import { Table } from 'components/common/table';
import { TabsContentsSpacer } from 'components/common/tabs';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import {
    canTouchRoleOrApp,
    getCompliantStatus,
    ModalAddEditType,
} from 'components/groups/groups-utils';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { displayWarningIfFewOwners } from 'components/groups/manage-group/manage-group-utils';
import AddEditAppModalActionButton from 'components/groups/manage-group/roles/buttons/add-edit-app-modal-action-button';
import AddEditRoleModalActionButton from 'components/groups/manage-group/roles/buttons/add-edit-role-modal-action-button';
import AddPocModalActionButton from 'components/groups/manage-group/roles/buttons/add-poc-modal-action-button';
import {
    ApplicationsTableColumns,
    tableColumnsApplications,
} from 'components/groups/manage-group/roles/table-columns-group-applications';
import {
    OwnershipsTableColumnNames,
    tableColumnsGroupOwnerships,
} from 'components/groups/manage-group/roles/table-columns-group-ownerships';
import { tableColumnsPointsOfContact } from 'components/groups/manage-group/roles/table-columns-group-pocs';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useFetchSimple } from 'utils/misc-hooks';
import { strCmp, useSortColumnHandler } from 'utils/sort-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface IGroupRolesProps {
    group: IGroup;
    onRoleDemoted: (principalId: string) => void;
}

export default function ManageGroupRoles(props: IGroupRolesProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);
    const groupContext = useContext(ManageGroupContext);

    const isFeatureFlagGroupsPOCEnabled = useFeatureFlag(FeatureFlagKeys.groupsPOC).enabled;
    const isMin2FTEOwnersFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.groupsMin2FteOwners)
        .enabled;
    const showNonExternalComponents = useFeatureFlag(FeatureFlagKeys.enableNonExternalComponents)
        .enabled;

    const showApplicationMemberships = useFeatureFlag(FeatureFlagKeys.enableNonExternalComponents)
        .enabled;

    const [applications, setApplications] = useState<IApplicationGroupMembership[]>([]);
    const [isInitialApplicationsLoad, setIsInitialApplicationsLoad] = useState<boolean>(true);
    const [hasErrorFetchingApplications, setHasErrorFetchingApplications] = useState<boolean>(
        false,
    );

    const [
        { sortColumn: ownershipsSortColumn, sortAscending: ownershipsSortAscending },
        ownershipsSortColumnHandler,
    ] = useSortColumnHandler('');

    const [
        { sortColumn: applicationsSortColumn, sortAscending: applicationsSortAscending },
        applicationsSortColumnHandler,
    ] = useSortColumnHandler('');

    useFetchSimple<IApplicationGroupMembership[]>({
        dependencies: [props.group.id],
        canPerformFetch: !!props.group.id,
        fetchFunc: async () => {
            let applicationMemberships;
            if (showApplicationMemberships) {
                applicationMemberships = GroupClient.listApplicationGroupMembershipsInGroup(
                    authContext,
                    props.group.id,
                );
            } else {
                applicationMemberships = [] as IApplicationGroupMembership[];
            }
            return applicationMemberships;
        },
        onSuccess: (result) => {
            setApplications(result);
            setHasErrorFetchingApplications(false);
        },
        onError: () => {
            setHasErrorFetchingApplications(true);
        },
        onFinally: () => {
            setIsInitialApplicationsLoad(false);
        },
    });

    const onRoleAddOrUpdate = (role: IGroupMembership, mode: ModalAddEditType): void => {
        switch (mode) {
            case ModalAddEditType.add:
                groupContext.setGroupOwners((currentValue) => currentValue.concat(role));
                break;
            case ModalAddEditType.update:
                groupContext.setGroupOwners((currentValue) => {
                    const ix = currentValue.findIndex(
                        (thisOne) => thisOne.personnelId === role.personnelId,
                    );
                    const newValue = [...currentValue];
                    newValue.splice(ix, 1, role);
                    return newValue;
                });
                break;
            default:
                break;
        }
    };

    const onPocAdded = (updatedGroup: IGroup): void => {
        groupContext.setGroup(updatedGroup);
    };

    const onPocDeleted = (updatedGroup: IGroup): void => {
        groupContext.setGroup(updatedGroup);
    };

    const onAppAddOrUpdate = (app: IApplicationGroupMembership, mode: ModalAddEditType): void => {
        switch (mode) {
            case ModalAddEditType.add:
                setApplications((currentValue) => currentValue.concat(app));
                break;
            case ModalAddEditType.update:
                setApplications((currentValue) => {
                    const ix = currentValue.findIndex((thisOne) => thisOne.id === app.id);
                    const newValue = [...currentValue];
                    newValue.splice(ix, 1, app);
                    return newValue;
                });
                break;
            default:
                break;
        }
    };

    const onAppDeleted = (appParam: IApplicationGroupMembership): void => {
        setApplications((currentValue) => {
            return currentValue.filter((app) => app.applicationId !== appParam.applicationId);
        });
    };

    const sortOwnerships = (owners: IGroupMembership[]): IGroupMembership[] => {
        type A = IGroupMembership;

        const chooseSortCmp = (): ((r1: A, r2: A) => number) => {
            switch (ownershipsSortColumn) {
                case OwnershipsTableColumnNames.name:
                    return (r1: A, r2: A): number =>
                        ownershipsSortAscending *
                        strCmp(r1.firstName + r1.lastName, r2.firstName + r2.lastName);
                case OwnershipsTableColumnNames.role:
                    return (r1: A, r2: A): number =>
                        ownershipsSortAscending * strCmp(r1.role, r2.role);
                case OwnershipsTableColumnNames.status:
                    return (r1: A, r2: A): number =>
                        ownershipsSortAscending *
                        strCmp(
                            getCompliantStatus(!!r1.compliant, false, false),
                            getCompliantStatus(!!r2.compliant, false, false),
                        );
                case OwnershipsTableColumnNames.dateModified:
                    return (r1: A, r2: A): number =>
                        ownershipsSortAscending *
                        (r1.modifiedTimestampUTC - r2.modifiedTimestampUTC);
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp();
        return [...owners].sort(sortCmp);
    };

    const sortApplications = (
        applications: IApplicationGroupMembership[],
    ): IApplicationGroupMembership[] => {
        type A = IApplicationGroupMembership;

        const chooseSortCmp = (): ((r1: A, r2: A) => number) => {
            switch (ownershipsSortColumn) {
                case ApplicationsTableColumns.name:
                    return (r1: A, r2: A): number =>
                        applicationsSortAscending * strCmp(r1.applicationName, r2.applicationName);
                case ApplicationsTableColumns.role:
                    return (r1: A, r2: A): number =>
                        applicationsSortAscending * strCmp(r1.role, r2.role);
                case ApplicationsTableColumns.dateModified:
                    return (r1: A, r2: A): number =>
                        applicationsSortAscending *
                        (r1.modifiedTimestampUTC - r2.modifiedTimestampUTC);
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp();
        return [...applications].sort(sortCmp);
    };

    const canTouchRoleOrAppVar = useMemo(() => {
        return (
            authContext.isInRole(Role.GroupAdmin) ||
            canTouchRoleOrApp(
                groupContext.groupOwners.find(
                    (owner) => owner.personnelId === principalUserContext.principalRecord.id,
                ),
            )
        );
    }, [groupContext.groupOwners]);

    const ownershipsTableColumns = useMemo(() => {
        return tableColumnsGroupOwnerships({
            enableOwnerDemote: groupContext.isEnableOwnerDemote,
            group: props.group,
            sortColumn: ownershipsSortColumn,
            sortAscending: ownershipsSortAscending === 1,
            canTouchRoleOrApp: canTouchRoleOrAppVar,
            onRoleAddOrUpdate: onRoleAddOrUpdate,
            sortColumnHandler: ownershipsSortColumnHandler,
            onRoleDemoted: props.onRoleDemoted,
        });
    }, [
        props.group,
        ownershipsSortColumn,
        ownershipsSortAscending,
        canTouchRoleOrAppVar,
        groupContext.isEnableOwnerDemote,
        onRoleAddOrUpdate,
        ownershipsSortColumnHandler,
        props.onRoleDemoted,
    ]);

    const showPoc = useCallback((poc: IPointOfContact): JSX.Element => {
        const { contactValue, contactValueType } = poc;
        if (contactValueType === PocTypeEnum.Employee) {
            return <CoreEmployeeHoverCardFromPrincipalId principalId={contactValue} />;
        }
        return <a href={'mailto:' + contactValue}>{contactValue}</a>;
    }, []);

    const pocsTableColumnNames = useMemo(
        () =>
            tableColumnsPointsOfContact({
                group: props.group,
                canDeletePoc: canTouchRoleOrAppVar,
                showPoc,
                onPocDeleted: onPocDeleted,
            }),
        [props.group, canTouchRoleOrAppVar, showPoc, onPocDeleted],
    );

    const applicationsTableColumns = useMemo(() => {
        return tableColumnsApplications({
            group: props.group,
            sortColumn: applicationsSortColumn,
            sortAscending: applicationsSortAscending === 1,
            canTouchRoleOrApp: canTouchRoleOrAppVar,
            onAppDeleted,
            onAppAddOrUpdate,
            sortColumnHandler: applicationsSortColumnHandler,
        });
    }, [
        props.group,
        applicationsSortColumn,
        applicationsSortAscending,
        canTouchRoleOrAppVar,
        onAppDeleted,
        onAppAddOrUpdate,
        applicationsSortColumnHandler,
    ]);

    const sortedOwners = useMemo(() => {
        return sortOwnerships(groupContext.groupOwners);
    }, [groupContext.groupOwners, ownershipsSortAscending, ownershipsSortColumn]);

    const sortedApplications = useMemo(() => {
        return sortApplications(applications);
    }, [applications, applicationsSortAscending, applicationsSortColumn]);

    const displayRolesTable = (): JSX.Element => {
        return (
            <>
                {displayWarningIfFewOwners(
                    groupContext.groupOwners,
                    groupContext.ownerIsFTE,
                    groupContext.isGroupOwnersObtained,
                    isMin2FTEOwnersFeatureFlagEnabled,
                )}
                <HorizontalBar>
                    <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                        <h1
                            className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont} ${globalStyles.removeTopBottomMargins}`}>
                            Roles
                        </h1>
                    </Stack.Item>
                    <Stack.Item>
                        <AddEditRoleModalActionButton
                            enableOwnerDemote={true} // Demote must be allowed for adding roles so that any role can be added.
                            mode={ModalAddEditType.add}
                            group={props.group}
                            onRoleAddOrUpdate={onRoleAddOrUpdate}
                            canTouchRoleOrApp={canTouchRoleOrAppVar}
                        />
                    </Stack.Item>
                </HorizontalBar>
                <ProblemLoadingMsg problemLoadingMsg={groupContext.errorFetchingOwners}>
                    <Table
                        rows={sortedOwners}
                        tableColumns={ownershipsTableColumns}
                        shimmerLabel={
                            !groupContext.isGroupOwnersObtained
                                ? 'Loading ownership information...'
                                : ''
                        }
                        isFetchingData={!groupContext.isGroupOwnersObtained}
                        noDataText={'There is no ownership information'}
                        tableName='Group Owners'
                        shimmerLines={5}
                    />
                </ProblemLoadingMsg>
            </>
        );
    };
    const displayPocsTable = (): JSX.Element => {
        return (
            <>
                <HorizontalBar>
                    <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                        <h1
                            className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont} ${globalStyles.removeTopBottomMargins}`}>
                            Points of Contact
                        </h1>
                    </Stack.Item>
                    <Stack.Item>
                        {canTouchRoleOrAppVar && (
                            <AddPocModalActionButton
                                canAddPoc={canTouchRoleOrAppVar}
                                group={props.group}
                                onPocAdded={onPocAdded}
                            />
                        )}
                    </Stack.Item>
                </HorizontalBar>
                <Table
                    rows={props.group.pointsOfContact}
                    tableColumns={pocsTableColumnNames}
                    shimmerLabel={''}
                    isFetchingData={false}
                    noDataText={'There is no point of contact information'}
                    tableName='Points of Contact'
                    shimmerLines={5}
                />
            </>
        );
    };
    const displayApplicationsTable = (): JSX.Element => {
        return (
            <>
                <HorizontalBar>
                    <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                        <h1
                            className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont} ${globalStyles.removeTopBottomMargins}`}>
                            Applications
                        </h1>
                    </Stack.Item>
                    <Stack.Item>
                        <AddEditAppModalActionButton
                            mode={ModalAddEditType.add}
                            group={props.group}
                            onAppAddOrUpdate={onAppAddOrUpdate}
                            canTouchRoleOrApp={canTouchRoleOrAppVar}
                        />
                    </Stack.Item>
                </HorizontalBar>
                <ProblemLoadingMsg
                    problemLoadingMsg={
                        hasErrorFetchingApplications ? 'Error fetching applications' : ''
                    }>
                    <Table
                        rows={sortedApplications}
                        tableColumns={applicationsTableColumns}
                        isFetchingData={isInitialApplicationsLoad}
                        noDataText={'There is no application information'}
                        tableName='Group Applications'
                        shimmerLines={5}
                    />
                </ProblemLoadingMsg>
            </>
        );
    };

    return (
        <div>
            <TabsContentsSpacer />
            {displayRolesTable()}
            {isFeatureFlagGroupsPOCEnabled && (
                <>
                    <Spacer marginTop={30} />
                    {displayPocsTable()}
                </>
            )}
            {showNonExternalComponents && (
                <>
                    <Spacer marginTop={30} />
                    {displayApplicationsTable()}
                </>
            )}
        </div>
    );
}
