import { Label } from '@fluentui/react';
import { IUserAccessReviewAssignmentChangeDetails } from 'clients/group-client';
import { AssignmentValueTableCell } from 'components/core/attributes/assignment-value-table-cell';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import React from 'react';

export interface IAssignmentChangeUarDetailsProps {
    principalId: string;
    assignmentChangeDetails: IUserAccessReviewAssignmentChangeDetails;
}

export default function AssignmentChangeUarDetails(
    props: IAssignmentChangeUarDetailsProps,
): JSX.Element {
    return (
        <div style={styles.container}>
            <div style={styles.label}>
                <Label>Employee</Label>
            </div>
            <div style={styles.value}>
                <CoreEmployeeHoverCardFromPrincipalId
                    principalId={props.principalId}
                    showMiniCardAlias={true}
                />
            </div>

            <div style={styles.label}>
                <Label>Attribute</Label>
            </div>
            <div style={styles.value}>
                <span>{props.assignmentChangeDetails.attributeName}</span>
            </div>

            <div style={styles.label}>
                <Label>Previous Value</Label>
            </div>
            <div style={styles.value}>
                {props.assignmentChangeDetails.previousAssignmentValue != null ? (
                    <AssignmentValueTableCell
                        attributeType={props.assignmentChangeDetails.attributeDataType}
                        value={props.assignmentChangeDetails.previousAssignmentValue}
                    />
                ) : (
                    <span>No value</span>
                )}
            </div>

            <div style={styles.label}>
                <Label>New Value</Label>
            </div>
            <div style={styles.value}>
                {props.assignmentChangeDetails.currentAssignmentValue != null ? (
                    <AssignmentValueTableCell
                        attributeType={props.assignmentChangeDetails.attributeDataType}
                        value={props.assignmentChangeDetails.currentAssignmentValue}
                    />
                ) : (
                    <span>No value</span>
                )}
            </div>
        </div>
    );
}

const styles = {
    container: {
        padding: '20px 0px',
        display: 'grid',
        gridTemplateColumns: '1fr 10fr',
        gridRowGap: '50px',
    },
    label: {},
    value: {
        padding: '5px 0px',
    },
};
